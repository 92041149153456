import { NgForOf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TaskStatusChipComponent } from '@clover/conversations-v4/tasks/task-status-chip/task-status-chip.component';
import { DateFormatDistancePipe } from '@clover/core/pipes/date-format.pipe';
import { CompanyTask } from '@clover/dashboard-v2/mocks';
import { CoreModule } from '@core/core.module';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TableComponent } from '@design/table/table.component';
import { TdComponent } from '@design/table/td/td.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

export const getSlaLocale = (): Locale => {
  return {
    formatDistance: (token, count) => {
      const formatDistanceLocale = {
        xSeconds: '{{count}}s',
        xMinutes: '{{count}}m',
        xHours: '{{count}}h',
        xDays: '{{count}}d',
        xMonths: '{{count}}mo',
        xYears: '{{count}}y',
      };
      return formatDistanceLocale[token].replace('{{count}}', count);
    },
  };
};

@Component({
  selector: 'cc-company-tasks',
  standalone: true,
  imports: [
    ButtonComponent,
    CoreModule,
    NgForOf,
    NgOptimizedImage,
    TableComponent,
    ThComponent,
    TooltipDirective,
    TaskStatusChipComponent,
    TdComponent,
    TrComponent,
    DateFormatDistancePipe,
  ],
  templateUrl: './company-tasks.component.html',
  styleUrls: ['./company-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyTasksComponent {
  @Input()
  tasks: CompanyTask[] = [];

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly getSlaLocale = getSlaLocale;

  getSlaOverdue(task: CompanyTask): boolean {
    return new Date(task.dueDate) < new Date();
  }
}
