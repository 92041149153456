import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import {
  type OffsetPagingOptions,
  type OffsetPagingWrapper,
  getOffsetPagingOptionsParams,
} from '@clover/core/helpers/paging';
import { ccPreventDuplicateSubscriptions } from '@clover/core/helpers/prevent-duplicate-subscriptions';
import { HttpService } from '@clover/core/services/http.service';
import {
  type KeyTitle,
  type Product,
  type ProductAccessType,
  type ProductDimensionsUnitOfMeasure,
  type ProductImage,
  type ProductImageType,
  type ProductPreview,
  type ProductStatus,
  type ProductTemperatureUnitOfMeasure,
  type ProductUnitOfMeasure,
} from '@clover/products-v2/state/products-state.model';
import { type Observable, type Subscription, filter, map, Subject } from 'rxjs';

interface KeyTitleResponse {
  key: string;
  title: string;
  description?: string;
  sortOrder?: number;
}

interface UnitOfMeasureResponse {
  value: number;
  unitOfMeasure: KeyTitleResponse;
}

interface ProductImageResponse {
  logo: {
    logoUrl: string;
    fullSizeLogoUrl: string;
    logoSettings: string;
    modifiedAt: string;
  };
  type: ProductImageType;
  slotId: number;
}

interface ProductResponse {
  id: number;
  vendor: {
    internalId: string;
    internalName: string;
    companyId: number;
    deferredAliasId: number;
    companyName: string;
  };
  globalProductClassification: {
    segment: KeyTitleResponse;
    family: KeyTitleResponse;
    class: KeyTitleResponse;
    category: KeyTitleResponse;
  };
  logos: ProductImageResponse[];
  accessType: KeyTitleResponse;
  status: ProductStatus;
  name: string;
  marketingMessage: string;
  packSize: string;
  manufactureProductCode: string;
  brandName: string;
  globalTradeIdentificationNumber: string;
  internalItemNumber: string;
  department: string;
  productId: string;
  categoryCodes: string;
  length: UnitOfMeasureResponse;
  height: UnitOfMeasureResponse;
  width: UnitOfMeasureResponse;
  storageTemperatureMinimum: UnitOfMeasureResponse;
  storageTemperatureMaximum: UnitOfMeasureResponse;
}

interface ProductPreviewResponse {
  vendorCompanyId: number;
  vendorCompanyName: string;
  internalVendorId: string;
  internalVendorName: string;
  status: ProductStatus;
  accessType: ProductAccessType;
  id: number;
  name: string;
  packSize: string;
  marketingMessage: string;
  marketingLogoUrl: string;
  brandName: string;
  globalTradeIdentificationNumber: string;
  internalItemNumber: string;
}

function mapKeyTitle<KeyType>(k: KeyTitleResponse): KeyTitle<KeyType> {
  return {
    key: k.key as KeyType,
    title: k.title,
    description: k.description,
    sortOrder: k.sortOrder,
  };
}

function mapUnitOfMeasure<UnitOfMeasureType>(u: UnitOfMeasureResponse): ProductUnitOfMeasure<UnitOfMeasureType> {
  return {
    value: u.value,
    unitOfMeasure: mapKeyTitle<UnitOfMeasureType>(u.unitOfMeasure),
  };
}

function mapProductImage(i: ProductImageResponse): ProductImage {
  return {
    slotId: i.slotId,
    imageUrl: i.logo.logoUrl,
    type: i.type,
    modifiedAt: i.logo.modifiedAt,
  };
}

function mapProduct(p: ProductResponse): Product {
  return {
    id: p.id,
    name: p.name,
    description: p.marketingMessage,
    images: p.logos.map(mapProductImage),
    vendor: {
      companyId: p.vendor.companyId,
      companyName: p.vendor.companyName,
      internalCompanyId: p.vendor.internalId,
      internalCompanyName: p.vendor.internalName,
      deferredAliasId: p.vendor.deferredAliasId,
    },
    status: p.status,
    access: (p.accessType?.key as ProductAccessType) || undefined,
    metadata: {
      brand: p.brandName,
      internalItemNumber: p.internalItemNumber,
      additionalProductId: p.productId,
      department: p.department,
      categoryCodes: p.categoryCodes.split(',').map((c) => c.trim()),
      mpc: p.manufactureProductCode,
      gpc: {
        segment: mapKeyTitle<string>(p.globalProductClassification.segment),
        family: mapKeyTitle<string>(p.globalProductClassification.family),
        class: mapKeyTitle<string>(p.globalProductClassification.class),
        category: mapKeyTitle<string>(p.globalProductClassification.category),
      },
      gtin: p.globalTradeIdentificationNumber,
      packSize: p.packSize,
      length: mapUnitOfMeasure<ProductDimensionsUnitOfMeasure>(p.length),
      height: mapUnitOfMeasure<ProductDimensionsUnitOfMeasure>(p.height),
      width: mapUnitOfMeasure<ProductDimensionsUnitOfMeasure>(p.width),
      temperatureMinimum: mapUnitOfMeasure<ProductTemperatureUnitOfMeasure>(p.storageTemperatureMinimum),
      temperatureMaximum: mapUnitOfMeasure<ProductTemperatureUnitOfMeasure>(p.storageTemperatureMaximum),
    },
  };
}

function mapProductPreview(p: ProductPreviewResponse): ProductPreview {
  return {
    id: p.id,
    name: p.name,
    description: p.marketingMessage || '',
    imageUrl: p.marketingLogoUrl,
    vendor: {
      companyId: p.vendorCompanyId,
      companyName: p.vendorCompanyName,
      internalCompanyId: p.internalVendorId,
      internalCompanyName: p.internalVendorName,
      deferredAliasId: undefined,
    },
    status: p.status,
    access: p.accessType,
    metadata: {
      brand: p.brandName,
      internalItemNumber: p.internalItemNumber,
      packSize: p.packSize,
      gtin: p.globalTradeIdentificationNumber,
    },
  };
}

export function mapProductToProductPreview(p: Product): ProductPreview {
  return {
    id: p.id,
    name: p.name,
    description: p.description,
    imageUrl: p.images[0]?.imageUrl,
    vendor: p.vendor,
    status: p.status,
    access: p.access,
    metadata: {
      brand: p.metadata.brand,
      internalItemNumber: p.metadata.internalItemNumber,
      packSize: p.metadata.packSize,
      gtin: p.metadata.gtin,
    },
  };
}

export type ProductsSortingProperty =
  | 'name'
  | 'brandName'
  | 'vendor'
  | 'globalTradeIdentificationNumber'
  | 'packSize'
  | 'status'
  | 'internalItemNumber'
  | 'accessType';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);

  private _productUpdated$ = new Subject<Product>();

  subscribeToProductUpdates(id: number): Observable<Product> {
    return this._productUpdated$.asObservable().pipe(filter((product) => product.id === id));
  }

  getProduct(companyId: number, id: number): Observable<Product> {
    const urlTree = this.router.createUrlTree(['api', 'companies', companyId, 'products', id]);

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<ProductResponse>(path).pipe(map(mapProduct));
  }

  searchProducts(
    companyId: number,
    query: string,
    worklistId: number | undefined,
    pagingOptions?: OffsetPagingOptions<ProductsSortingProperty>,
  ): Observable<OffsetPagingWrapper<ProductPreview>> {
    const urlTree = this.router.createUrlTree(['api', 'companies', companyId, 'products', 'search'], {
      queryParams: {
        query: query || undefined,
        worklistId: worklistId || undefined,
        ...getOffsetPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http.postV2<OffsetPagingWrapper<ProductPreviewResponse>>(path, {}).pipe(
      map((response) => ({
        ...response,
        data: response.data.map(mapProductPreview),
      })),
    );
  }

  deleteProduct(companyId: number, id: number): Observable<void> {
    const urlTree = this.router.createUrlTree(['api', 'companies', companyId, 'products', id]);

    const path = this.serializer.serialize(urlTree);
    return this.http.deleteV2(path);
  }

  @ccPreventDuplicateSubscriptions('last-wins')
  private requestProductUpdate(companyId, productId: number): Subscription {
    return this.getProduct(companyId, productId).subscribe((product) => this._productUpdated$.next(product));
  }
}
