import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PageWithSubnavComponent } from '../../stories/layout/page-with-subnav/page-with-subnav.component';

@Component({
  selector: 'cc-products-page',
  standalone: true,
  imports: [PageWithSubnavComponent, RouterOutlet],
  templateUrl: './products-page.component.html',
  styleUrl: './products-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsPageComponent {}
