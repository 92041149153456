import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { DashboardComponent } from '@clover/dashboard-v2/dashboard.component';
import { MarkdownModule } from 'ngx-markdown';
import { provideStates } from '@ngxs/store';
import { WikiState } from '@network/company/state/wiki/wiki.state';
import { dashboardConnectionsResolver } from './dashboard-connections.resolver';

export const dashboardRoutes: Routes = [
  {
    path: '',
    providers: [importProvidersFrom(MarkdownModule.forRoot()), provideStates([WikiState])],
    resolve: {
      connections: dashboardConnectionsResolver,
    },
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: ':id',
        component: DashboardComponent,
      },
    ],
  },
];
