<cc-table
  [ccEmptyRowTemplate]="emptyRowTemplate"
  [ccSearchPlaceholder]="'Search for an order...'"
  ccPadding="medium"
>
  <ng-container slot="cc-table-title"> Orders</ng-container>

  <ng-container slot="cc-table">
    <thead>
      <tr>
        <th ccTh>Purchase Order #</th>
        <th ccTh>Sender's Company</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let order of orders">
        <tr
          (click)="orderClick.emit(order)"
          [ccClickable]="clickable"
          ccTr
        >
          <td>
            <div class="order-column">
              <div class="order-column__details">
                <div class="order-column__details-title">{{ order.poNumber }}</div>

                <div
                  [style.background-color]="getStatusBgColor(order)"
                  [style.color]="getStatusFgColor(order)"
                  class="order-column__details-status"
                >
                  {{ getStatusText(order) }}
                </div>
              </div>

              <div class="order-column__description">Total PO amount: {{ order.totalPOAmount | currency: 'USD' }}</div>
            </div>
          </td>

          <td ccTd>
            <div class="performer-column">
              <img
                [alt]="order.retailer"
                [ccTooltip]="order.retailer"
                src="https://picsum.photos/seed/{{ order.poNumber }}/200"
              />

              <div class="performer-column__name">{{ order.retailer }}</div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </ng-container>
</cc-table>

<ng-template #emptyRowTemplate> There are no orders to display.</ng-template>
