import { inject, Injectable } from '@angular/core';
import { CampaignsService } from './campaigns.service';
import { CampaignStatus, FilterableCampaignStatus, type CampaignPreview } from './campaigns-state.model';
import { PagingOrder } from '@clover/core/helpers/paging';
import { map, type Observable } from 'rxjs';
import { DateService } from '@clover/core/services/date.service';
import { generateCsv } from '@clover/core/helpers/generate-csv';

@Injectable({
  providedIn: 'root',
})
export class CampaignsExportService {
  private readonly campaignsService = inject(CampaignsService);
  private readonly dateService = inject(DateService);

  exportAllCampaigns(): Observable<string> {
    // TODO (Oleksandr D): Campaign exporting should be handled by BE. Rewrite when BE supports exporting campaigns.
    return this.campaignsService
      .searchCampaigns('', FilterableCampaignStatus.All, {
        limit: 2500,
        offset: 0,
        orderBy: 'name',
        order: PagingOrder.Descending,
      })
      .pipe(
        map((response) => {
          const { data } = response;

          const csvData = data.map((campaign) => {
            const name = campaign.name || 'N/A';
            const assignedTo = this.getAssignedToCell(campaign);
            const deliveredAt = this.getDeliveredAtCell(campaign);
            const status = this.getStatusCell(campaign);
            const completion = campaign.completion ? `${campaign.completion}%` : '0%';

            return {
              ['Campaign name']: name,
              ['Assigned to']: assignedTo,
              ['Delivered']: deliveredAt,
              ['Status']: status,
              ['Completion']: completion,
            };
          });

          return generateCsv(csvData);
        }),
      );
  }

  private getAssignedToCell(campaign: CampaignPreview): string {
    if (!campaign.previewCompany && !campaign.companiesCount) return 'N/A';

    if (!campaign.previewCompany)
      return `${campaign.companiesCount} ${campaign.companiesCount > 1 ? 'companies' : 'company'}`;

    if (campaign.companiesCount > 1) return `${campaign.previewCompany.name}, +${campaign.companiesCount - 1} more`;

    return campaign.previewCompany.name;
  }

  private getDeliveredAtCell(campaign: CampaignPreview): string {
    if (!campaign.deliveredAt) return 'N/A';

    const date = this.dateService.representLocalDateInProfileTimezoneDate(new Date(campaign.deliveredAt));
    const baseDate = this.dateService.representLocalDateInProfileTimezoneDate(new Date());

    return `${this.dateService.formatDistance(date, baseDate, {})} ago`;
  }

  private getStatusCell(campaign: CampaignPreview): string {
    const { status } = campaign;

    switch (status) {
      case CampaignStatus.Completed:
        return 'Completed';
      case CampaignStatus.InProgress:
        return 'In progress';
      case CampaignStatus.Closed:
        return 'Closed';
      case CampaignStatus.Draft:
        return 'Draft';
      default:
        return 'N/A';
    }
  }
}
