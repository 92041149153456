<cc-table
  [ccSearchFormControl]="queryFormControl"
  [ccSearchable]="true"
  [ccStickyHeader]="true"
  ccPadding="medium"
  ccSearchPlaceholder="Search tasks..."
  [ccEmptyRowTemplate]="emptyRowTemplate"
>
  <ng-container slot="cc-table-title">
    @if (tasksCount$ | async; as tasksCount) {
      {{ tasksCount }} {{ tasksCount === 1 ? 'task' : 'tasks' }}
    }
  </ng-container>

  <ng-container slot="сс-table-custom-actions">
    <cc-button-group>
      <app-button
        (click)="changeType(TaskType.Received)"
        class="cc-task-type-filter"
        [active]="(type$ | async) === TaskType.Received"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Secondary"
      >
        <div class="cc-task-type-filter__content">
          Received

          @if (usersIncompleteTasksCount$ | async; as incompleteTasksCount) {
            @if (incompleteTasksCount > 0) {
              <cc-nav-counter-chip ccTooltip="Incomplete tasks assigned to you">
                {{ incompleteTasksCount | ccLimitNumber: 2 }}
              </cc-nav-counter-chip>
            }
          }
        </div>
      </app-button>

      <app-button
        (click)="changeType(TaskType.Sent)"
        [active]="(type$ | async) === TaskType.Sent"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Secondary"
      >
        Sent
      </app-button>
    </cc-button-group>

    <cc-select [formControl]="statusSelectFormControl">
      <ng-container ccOptions>
        @for (option of statusSelectItems; track option.id) {
          <cc-select-item
            [id]="option.id"
            [value]="option.title"
            [payload]="option.payload"
          />
        }
      </ng-container>
    </cc-select>
  </ng-container>

  <ng-container slot="cc-table">
    <thead>
      <tr ccTr>
        <th
          ccTh
          ccThTooltip="Completion"
          (ccSortChange)="changeSort('completionRate', $event)"
          [ccSortDirection]="completionSortingOrder$ | async"
          [ccSortable]="true"
          ccWidth="56px"
        ></th>

        <th
          ccTh
          (ccSortChange)="changeSort('name', $event)"
          [ccSortDirection]="nameSortingOrder$ | async"
          [ccSortable]="true"
        >
          Task
        </th>

        <th
          ccTh
          (ccSortChange)="changeSort('createdAt', $event)"
          [ccSortDirection]="assignedAtSortingOrder$ | async"
          [ccSortable]="true"
          ccWidth="184px"
        >
          Assigned
        </th>

        <th
          ccTh
          ccWidth="184px"
        >
          Assignee's company
        </th>

        <th
          ccTh
          ccHorizontalAlignment="center"
          ccWidth="78px"
        >
          Assigner
        </th>

        <th
          ccTh
          ccHorizontalAlignment="center"
          ccWidth="78px"
        >
          Assignee
        </th>
      </tr>
    </thead>

    <tbody>
      @for (task of tasks$ | async; track task.id) {
        <tr
          routerLinkActive
          #rowLink="routerLinkActive"
          [ccSelected]="rowLink.isActive"
          ccTr
          [ccClickable]="true"
          [routerLink]="[task.id]"
          [queryParamsHandling]="'preserve'"
        >
          <cc-task-table-row
            [showCompletionProgress]="true"
            [showAssignedDateInSeparateColumn]="true"
            [showAssignerCompany]="true"
            [task]="task"
          />
        </tr>
      }
    </tbody>
  </ng-container>
</cc-table>

<ng-template #emptyRowTemplate> There are no tasks to display. </ng-template>
