import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { MobileInterstitialType } from '@core/components/mobile-interstitial/mobile-interstitial';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@design/buttons/button/button.component';

@Component({
  selector: 'cc-mobile-interstitial',
  templateUrl: './mobile-interstitial.component.html',
  styleUrls: ['./mobile-interstitial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, ButtonComponent],
})
export class MobileInterstitialComponent {
  @Input()
  type: Exclude<MobileInterstitialType, MobileInterstitialType.None> = MobileInterstitialType.SmallWindow;

  dismiss = output<void>();

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly MobileInterstitialType = MobileInterstitialType;
}
