import { Component, type OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ToastrService } from '@core/services/toastr.service';
import { ModalService } from '@core/services/modal.service';
import { type ServerError } from '@core/models/serverError';
import { AuthService } from '../auth.service';
import { TermsModalComponent } from '../modals/terms-modal/terms-modal.component';
import { GooglePhoneValidator } from '@core/validators/google-phone.validator';
import { UserService } from '@core/services/user.service';
import { SelectComponent, type SelectOption } from '@core/components/select/select.component';
import { EnumService } from '@core/services/enum.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { PasswordInputComponent } from '@core/components/password-input/password-input.component';
import { NgxMaskDirective } from 'ngx-mask';
import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';
import { NgClass } from '@angular/common';
import { LoaderComponent } from '@core/components/loader/loader.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { PAGE_URL } from '@core/constants/page-url';
import { CheckboxComponent } from '@design/forms/checkbox/checkbox.component';

@UntilDestroy()
@Component({
  selector: 'app-auth-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  standalone: true,
  imports: [
    AssetSrcDirective,
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    SelectComponent,
    NgxMaskDirective,
    PasswordInputComponent,
    ButtonLoadingDirective,
    RouterLink,
    TranslateModule,
    CheckboxComponent,
  ],
})
export class AuthSignupComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UserService);
  private readonly translateService = inject(TranslateService);
  private readonly router = inject(Router);
  private readonly toastr = inject(ToastrService);
  private readonly modalService = inject(ModalService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly enumService = inject(EnumService);
  public signupForm: UntypedFormGroup;
  public isLoading = false;
  public isLoaded = false;
  public isGoogleSignup = false;
  public isUserInvited = true;
  public isBusinessSignup = false;
  public invitedUserFirstName: string;
  public isFormSubmitted = false;
  public countryCodes: SelectOption[] = [];
  private invitationToken: string;
  private invitedUserEmail: string;

  constructor() {
    if (this.router.url === '/googleSignup') {
      this.isGoogleSignup = true;
      if (!this.authService.googleSignupData) {
        this.router.navigate(['/']);
      }
    }
  }

  get termsText() {
    return `${this.translateService.instant('signUp.iAcceptThe')} ${this.translateService.instant('signUp.termsOfUse')}`;
  }

  public ngOnInit(): void {
    this.countryCodes = this.enumService.getPhoneCodes();

    this.signupForm = new UntypedFormGroup(
      {
        firstName: new UntypedFormControl('', [Validators.required]),
        lastName: new UntypedFormControl('', [Validators.required]),
        countryCode: new UntypedFormControl(this.countryCodes[0].value),
        phoneNumber: new UntypedFormControl('', [Validators.required]),
        username: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.required]),
        terms: new UntypedFormControl(false, [Validators.requiredTrue]),
      },
      [GooglePhoneValidator('countryCode', 'phoneNumber')],
    );

    const params = this.activatedRoute.snapshot.queryParams;
    this.isUserInvited = params.invitation_token;

    if (this.isUserInvited) {
      this.authService
        .getInvitationInfo(params.invitation_token)
        .pipe(
          untilDestroyed(this),
          catchError((err) => {
            const errorCode = err && err.error && err.error.errors && err.error.errors[0]?.errorCode;
            this.authService.navigateAfterError(errorCode);
            return of(err);
          }),
          finalize(() => (this.isLoaded = true)),
        )
        .subscribe((res) => {
          if (res.isUserActive) {
            if (this.userService.profile$?.value?.username === res.invitedUserEmail) {
              this.router.navigate(['/network/invitationsReceived']);
            } else {
              this.router.navigate([PAGE_URL.LOGIN]);
            }
            return;
          }

          this.invitationToken = params.invitation_token;
          this.invitedUserEmail = res.invitedUserEmail;
          this.isBusinessSignup = this.isUserInvited && res.isBusinessSignup;
          this.invitedUserFirstName = res.invitedUserFirstName;

          if (!this.isBusinessSignup) {
            const email = this.signupForm.get('username');
            email.setValue(this.invitedUserEmail);
            email.disable();
          } else {
            this.signupForm = new UntypedFormGroup(
              {
                password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
                terms: new UntypedFormControl(false, [Validators.requiredTrue]),
              },
              [],
            );
          }
        });
    } else {
      this.isLoaded = true;
    }

    if (this.isGoogleSignup) {
      this.signupForm.get('password').disable();
      this.signupForm.get('username').disable();
      this.signupForm.get('firstName').setValue(this.authService.googleSignupData.firstName);
      this.signupForm.get('lastName').setValue(this.authService.googleSignupData.lastName);
    }
  }

  public openTerms(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.modalService.open({
      content: TermsModalComponent,
      options: {
        size: 'xl',
      },
      inputs: {
        readonly: true,
      },
    });
  }

  public signinSocial(): void {
    this.authService
      .googleSignin()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res && res.redirectUrl) {
          window.location.href = res.redirectUrl;
        }
      });
  }

  public finishGoogleSignup(): void {
    const payload = this.signupForm.value;

    this.authService
      .completeSocialSignup({
        firstName: payload.firstName,
        lastName: payload.lastName,
        phoneNumber: `${payload.countryCode}${payload.phoneNumber}`,
      })
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.userService.login(res);
        }),
        catchError((err) => {
          this.handleServerError(err);
          return of(err);
        }),
      )
      .subscribe();
  }

  public async signup(): Promise<void> {
    this.isFormSubmitted = true;

    if (this.signupForm.invalid) {
      return;
    }
    if (this.isGoogleSignup) {
      this.finishGoogleSignup();
      return;
    }

    const payload = this.signupForm.value;
    payload.phoneNumber = payload.countryCode + payload.phoneNumber;

    if (this.isUserInvited) {
      payload.invitationToken = this.invitationToken;
      payload.username = this.invitedUserEmail;
    }

    this.isLoading = true;
    // [ToDo Andrii S.] Add Types here after Organic Signup will be enabled
    const signupPromise: Promise<any> = !this.isBusinessSignup
      ? this.authService.signup(payload)
      : this.authService.businessSignup(payload);

    await signupPromise
      .then((res) => {
        if (!this.isUserInvited) {
          this.authService.lastRegisteredEmail = payload.username;
          this.router.navigate(['/emailSent']);
          this.isLoading = false;
        } else {
          this.userService.login(res).finally(() => {
            this.isLoading = false;
          });
        }
      })
      .catch((err) => this.handleServerError(err))
      .finally(() => (this.isLoading = false));
  }

  private handleServerError(response: any): void {
    if (!response.error?.errors) {
      this.toastr.error(
        (response.error.errorMessage as string).replaceAll('`', '"') || 'common.errorMessages.unexpected',
      );
      return;
    }

    response.error.errors.forEach((err: ServerError) => {
      if (err.property === 'general') {
        this.toastr.serverError(err);
        return;
      }

      if (err.errorCode === 'organic_signup_disabled') {
        this.toastr.error('signUp.errors.organicSignupDisabled');
        return;
      }

      if (err.errorCode === 'user_already_exists' && this.isUserInvited) {
        this.toastr.displayServerErrors(response);
        return;
      }

      if (err.errorCode === 'invitation_already_accepted') {
        this.toastr.error('signUp.errors.linkExpired');
        return;
      }
      if (err.errorCode === 'allowed_only_registration_via_invitation') {
        this.toastr.error('signUp.errors.allowed_only_registration_via_invitation');
        return;
      }

      let field;

      if (err.property === 'email') {
        field = this.signupForm.get('username');
      } else {
        field = this.signupForm.get(err.property);
      }
      if (field) {
        field.setErrors({ ...field.errors, [err.errorCode]: err.errorMessage });
      }
    });
  }
}
