import { Component, type OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { type Company } from '@core/models/company';
import { UserService } from '@core/services/user.service';
import { RegistrationStatuses, type User } from '@core/models/user';
import { GeolocationService } from '@core/services/geolocation.service';
import { type Address } from '@core/models/address';
import { ToastrService } from '@core/services/toastr.service';
import { WebsiteValidator } from '@core/validators/website.validator';
import { CompanySearchComponent } from '@clover/company-lists/components/company-search/company-search.component';
import { PAGE_URL } from '@core/constants/page-url';
import { catchError, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { AddressSelectionComponent } from '@core/components/address-selection/address-selection.component';
import { AddressInputComponent } from '@core/components/address-input/address-input.component';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { CompanyInfoComponent } from '@core/components/company-info/company-info.component';
import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';
import { CompanySearchComponent as CompanySearchComponent_1 } from '../../../company-lists/components/company-search/company-search.component';
import { NgClass } from '@angular/common';
import { LoaderComponent } from '@core/components/loader/loader.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@UntilDestroy()
@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    AssetSrcDirective,
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    CompanySearchComponent_1,
    FormErrorMessagesComponent,
    CompanyInfoComponent,
    ButtonLoadingDirective,
    AddressInputComponent,
    AddressSelectionComponent,
    TranslateModule,
  ],
})
export class CompanySelectComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly userService = inject(UserService);
  private readonly geolocationService = inject(GeolocationService);
  private readonly toastr = inject(ToastrService);
  @ViewChild('companySearch') companySearchInput: CompanySearchComponent;
  public companyForm: UntypedFormGroup;
  public companySearchForm: UntypedFormGroup;
  public isLoaded = false;
  public isLoading = false;
  public showLoader = true;
  public currentStep: number;

  public selectedCompany: Company;
  public matchedCompanies: Company[] = [];

  public matchedAddress: Address;
  public enteredAddress: Address;
  public selectedAddress: Address;
  public steps = {
    companySearch: 1,
    companyDetails: 2,
    companyCreation: 3,
    addressSelection: 4,
    addressInvalid: 5,
    userHasCompany: 6,
  };
  private user: User;

  constructor() {
    this.companyForm = new UntypedFormGroup({
      companyName: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      address: new UntypedFormControl('', [Validators.required]),
      website: new UntypedFormControl('', [Validators.required, WebsiteValidator()]),
    });

    this.companySearchForm = new UntypedFormGroup({
      companyName: new UntypedFormControl('', [Validators.required]),
    });
  }

  public get isSearching(): boolean {
    return this.companySearchInput?.isSearching;
  }

  public get isBackBtnShown(): boolean {
    if (this.currentStep === this.steps.companyCreation) {
      return true;
    }

    return false;
  }

  public ngOnInit(): void {
    this.user = this.userService.userProfile;
    if (!this.user) {
      this.router.navigate([PAGE_URL.LOGIN]);
      return;
    }

    if (
      this.user.registrationStatus !== RegistrationStatuses.companySelection &&
      this.user.registrationStatus !== RegistrationStatuses.brokerageCompanySelection &&
      this.user.registrationStatus !== RegistrationStatuses.companyConfirmation
    ) {
      this.router.navigate(['/']);
      return;
    }

    this.isLoaded = true;

    if (this.user.registrationStatus === RegistrationStatuses.companyConfirmation) {
      this.currentStep = this.steps.userHasCompany;
      this.loadCompany(+this.user.suggestedCompanyId);
    } else if (
      (this.user.registrationStatus === RegistrationStatuses.companySelection ||
        this.user.registrationStatus === RegistrationStatuses.brokerageCompanySelection) &&
      this.user.suggestedCompanyId
    ) {
      this.currentStep = this.steps.companyDetails;
      this.loadCompany(+this.user.suggestedCompanyId);
    } else {
      this.currentStep = this.steps.companySearch;
      this.showLoader = false;
    }
  }

  public onSelectionChanged(company: Company): void {
    this.selectedCompany = company;
  }

  public onMatchesChanged(companies: Company[]): void {
    this.matchedCompanies = companies;
  }

  public confirmSelected(): void {
    this.isLoading = true;
    this.authService
      .confirmCompany()
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.userService.refreshAuthToken().then(async () => {
            await this.openProfileSetup();
          });
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public async openProfileSetup(): Promise<void> {
    await this.router.navigate([PAGE_URL.PROFILE_SETUP]);
    this.isLoading = false;
  }

  public checkAddress(): void {
    if (this.companyForm.invalid) {
      return;
    }
    this.enteredAddress = this.companyForm.value.address;
    this.isLoading = true;
    this.geolocationService
      .lookupAddress(this.enteredAddress)
      .then((matchedAddress) => {
        if (!matchedAddress) {
          this.currentStep = this.steps.addressInvalid;
          this.matchedAddress = null;
          this.isLoading = false;
          this.selectedAddress = this.enteredAddress;
          return;
        }

        this.selectedAddress = matchedAddress;
        if (this.geolocationService.areAddressesEqual(this.enteredAddress, matchedAddress)) {
          this.createCompany();
        } else {
          this.matchedAddress = matchedAddress;
          this.selectedAddress = this.matchedAddress;
          this.currentStep = this.steps.addressSelection;
          this.isLoading = false;
        }
      })
      .catch(() => (this.isLoading = false));
  }

  public finishAddressSelection(): void {
    this.companyForm.get('address').setValue({
      streetAddressLine1: this.selectedAddress.streetAddressLine1,
      streetAddressLine2: this.selectedAddress.streetAddressLine2 || '',
      city: this.selectedAddress.city,
      state: this.selectedAddress.state,
      zipCode: this.selectedAddress.zipCode,
    });
    this.createCompany();
  }

  public createCompany(): void {
    if (this.companyForm.invalid) {
      return;
    }
    const form = this.companyForm.value;

    this.isLoading = true;

    this.authService
      .createCompany({
        name: form.companyName,
        websiteUrl: form.website,
        address: this.selectedAddress,
      })
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.selectedCompany = res;
          this.confirmSelection();
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
      )
      .subscribe();
  }

  public confirmSelection(): void {
    this.isLoading = true;
    this.authService
      .assosiateCompany(this.selectedCompany.id)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.userService.profile$.next(res);
          this.userService.refreshAuthToken().then(async () => {
            await this.openProfileSetup();
          });
        }),
        catchError((err) => {
          this.isLoading = false;
          const error = err.error.errors[0];
          switch (error.errorCode) {
            case 'association_only_via_invitation':
              this.router.navigate(['/invitationRequired']);
              break;
            case 'user_email_domain_mismatched':
              this.router.navigate(['/emailNotAuthorized']);
              break;
            default:
              this.toastr.displayServerErrors(err);
              break;
          }
          return of(err);
        }),
      )
      .subscribe();
  }

  public submitCompanyName(): void {
    if (this.companySearchForm.invalid) {
      return;
    }
    const submittedName = this.companySearchForm.get('companyName').value;

    if (!this.selectedCompany && this.matchedCompanies?.length) {
      this.selectedCompany = this.matchedCompanies.find((company) => company.name === submittedName);
    }

    if (this.selectedCompany) {
      this.openCompanyDetails();
    } else {
      this.openCompanyCreation();
    }
  }

  public openCompanyCreation(): void {
    this.companyForm.get('companyName').setValue(this.companySearchForm.get('companyName').value);
    this.currentStep = this.steps.companyCreation;
  }

  public openCompanySearch(): void {
    this.selectedCompany = null;
    this.currentStep = this.steps.companySearch;
  }

  private loadCompany(companyId: number): void {
    this.authService
      .getCompanyDetails(+companyId)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.selectedCompany = res;
        }),
        finalize(() => (this.showLoader = false)),
      )
      .subscribe();
  }

  private openCompanyDetails(): void {
    this.currentStep = this.steps.companyDetails;
    this.showLoader = true;
    this.authService
      .getCompanyDetails(this.selectedCompany.id)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.selectedCompany = res;
        }),
        catchError((err) => {
          this.openCompanySearch();
          return of(err);
        }),
        finalize(() => (this.showLoader = false)),
      )
      .subscribe();
  }
}
