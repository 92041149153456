import { inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { type UploadedImageData, type User } from '@core/models/user';
import { type Company } from '@core/models/company';
import { type LoginData } from '@core/models/loginData';
import { HttpHeaders } from '@angular/common/http';
import { type Address } from '@core/models/address';
import { Router } from '@angular/router';
import { PAGE_URL } from '@core/constants/page-url';
import { type Observable } from 'rxjs';
import { UserService } from '@core/services/user.service';
import { ToastrService } from '@core/services/toastr.service';

export enum UserManagementErrorCodes {
  InvalidUser = 'invalid_user',
  InactivedUser = 'inactived_user',
  ChildUser = 'child_user',
  InvalidEmail = 'invalid_email',
  InvalidToken = 'invalid_token',
  EmailNotConfirmed = 'email_not_confirmed',
  EmailIsAlreadyConfirmed = 'email_is_already_confirmed',
  TwoFactorRequired = 'two_factor_required',
  InvalidEmailOrPassword = 'invalid_email_or_password',
  UserAlreadyExists = 'user_already_exists',
}

export enum UserInvitationErrorCodes {
  InvalidInvitation = 'invalid_invitation',
  ExpiredInvitation = 'expired_invitation',
  InactiveInvitation = 'inactive_invitation',
  AdministratorApproveRequired = 'administrator_approve_required',
  InvitationAlreadyAccepted = 'invitation_already_accepted',
  InvitationAlreadyDeclined = 'invitation_already_declined',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public lastRegisteredEmail: string;
  public emailConfirmationToken: string;
  public googleSignupData: { firstName: string; lastName: string };
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly jsonContentTypeHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private readonly userService = inject(UserService);
  private readonly toastr = inject(ToastrService);

  public signin(data: { username: string; password: string }): Observable<LoginData> {
    return this.http.postV2('api/auth/signin', data);
  }

  public signup(data: any): Promise<any> {
    return this.http.post('api/auth/signup', data);
  }

  public businessSignup(data: any): Promise<any> {
    return this.http.post('api/auth/businessSignup', data);
  }

  public confirmCode(data: { code: string; token: string }): Observable<void> {
    return this.http.postV2('api/auth/twoFactor/verify', data);
  }

  public resendCode(token: string): Observable<void> {
    return this.http.postV2('api/auth/twoFactor/resend', { token });
  }

  public resendEmailByToken(token: string): Observable<void> {
    return this.http.postV2('api/auth/email/resend', { token });
  }

  public resendEmail(email: string): Observable<void> {
    return this.http.postV2('api/auth/email/resend', { email });
  }

  public confirmEmail(token: string): Observable<LoginData> {
    return this.http.postV2('api/auth/email/confirm', { token });
  }

  public acceptToU(authToken: string): Observable<any> {
    return this.http.postV2('api/profile/terms/accept', {}, new HttpHeaders({ Authorization: `Bearer ${authToken}` }));
  }

  public getCompanyDetails(id: number): Observable<Company> {
    return this.http.getV2(`api/companies/${id}`);
  }

  public createCompany(data: { name: string; websiteUrl: string; address: Address }): Observable<Company> {
    return this.http.postV2('api/companies', data);
  }

  public assosiateCompany(companyId: number): Observable<User> {
    return this.http.postV2('api/profile/associateCompany', { companyId });
  }

  public confirmCompany(): Observable<void> {
    return this.http.postV2('api/profile/confirmCompany', null);
  }

  public completeProfile(data: {
    title: string;
    logo: UploadedImageData;
    communicationRoles: string[];
  }): Observable<User> {
    return this.http.postV2('api/profile/complete', data);
  }

  public googleSignin(): Observable<{ redirectUrl: string }> {
    return this.http.getV2('api/Auth/social/auth?Provider=google');
  }

  public completeSocialSignup(data: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }): Observable<LoginData> {
    return this.http.postV2('api/auth/social/authComplete', data);
  }

  public finishSocialAuth(): Observable<LoginData> {
    return this.http.postV2('api/auth/social/authComplete', {});
  }

  public getInvitationInfo(token: string): Observable<any> {
    return this.http.getV2(`api/companies/my/users/invitations?token=${token}`);
  }

  public getLandingPageSettings(): Observable<any> {
    return this.http.getV2(`api/companies/my/invitationPage`);
  }

  public sendPasswordReset(email: string): Observable<any> {
    return this.http.postV2(`api/auth/password/recover`, { email });
  }

  public resetPassword(newPassword: string, token: string): Observable<any> {
    return this.http.postV2(`api/auth/password/reset`, { newPassword, token });
  }

  public verifyForgotPassToken(token: string): Observable<any> {
    return this.http.postV2(`api/auth/password/reset/verify`, `"${token}"`, this.jsonContentTypeHeaders);
  }

  public verifyEmailConfirmToken(token: string): Observable<any> {
    return this.http.postV2(`api/auth/email/confirm/verify`, `"${token}"`, this.jsonContentTypeHeaders);
  }

  public completeBusinessSignup(data: any): Observable<any> {
    return this.http.postV2(`api/profile/business/complete`, data);
  }

  public navigateAfterError(errCode: string): Promise<boolean> {
    switch (errCode) {
      case UserInvitationErrorCodes.InactiveInvitation:
        return this.router.navigate(['/invitationInactive']);
      case UserInvitationErrorCodes.ExpiredInvitation:
        return this.router.navigate(['/invitationExpired']);
      case UserInvitationErrorCodes.AdministratorApproveRequired:
        return this.router.navigate(['/invitationRequired']);
      case UserInvitationErrorCodes.InvitationAlreadyAccepted:
        if (this.userService.isAuthenticated) {
          this.toastr.error('invitationAlreadyAcceptedPage.toastMessage');
          return this.router.navigate(['/dashboard']);
        }

        return this.router.navigate(['/invitationAlreadyAccepted']);
      default:
        return this.router.navigate([PAGE_URL.PAGE_NOT_FOUND]);
    }
  }

  public declineInvitation(data: { token: string; reason: string }): Observable<any> {
    return this.http.postV2(`api/companies/my/users/invitations/decline`, data);
  }

  public sendFirstQuestionAnswer(data: { token: string; answer: string }): Observable<any> {
    return this.http.postV2(`api/companies/my/users/invitations/firstQuestion`, data);
  }

  public sendSecondQuestionAnswer(data: { token: string; answer: string }): Observable<any> {
    return this.http.postV2(`api/companies/my/users/invitations/secondQuestion`, data);
  }

  public setLookingForCompanyType(data: { companyType: string }): Observable<any> {
    return this.http.postV2('api/profile/setLookingForCompanyType', data);
  }
}
