<div class="stats">
  <ng-container *ngFor="let stat of stats">
    <div
      [class.stats__item--arrow-down]="stat.arrowDown"
      [class.stats__item--decrease]="!stat.increase"
      class="stats__item"
    >
      <div class="stats__item-title">{{ stat.title }}</div>
      <div class="stats__item-description">{{ stat.description }}</div>
      <div class="stats__item-value">
        <i class="stats__item-value-indicator icon-arrow-bottom-line-thin"></i>
        <div class="stats__item-value-value">{{ stat.value }}</div>
      </div>
    </div>
  </ng-container>
</div>
