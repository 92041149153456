import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, type OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TaskDrawerComponent } from '@clover/conversations-v4/tasks/task-drawer/task-drawer.component';
import { TaskStatus, type Task } from '@clover/conversations-v4/tasks/tasks.model';
import { TasksService } from '@clover/conversations-v4/tasks/tasks.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TranslateModule } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { map, startWith, switchMap, type Observable } from 'rxjs';

@Component({
  selector: 'cc-tasks-list-drawer',
  standalone: true,
  imports: [AsyncPipe, TaskDrawerComponent, ButtonComponent, RouterLink, TranslateModule],
  templateUrl: './tasks-list-drawer.component.html',
  styleUrl: './tasks-list-drawer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksListDrawerComponent implements OnInit {
  task$: Observable<Task>;

  canOpenTaskWizard$: Observable<boolean>;

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private readonly store = inject(Store);
  private readonly tasksService = inject(TasksService);
  private readonly activatedRoute = inject(ActivatedRoute);

  ngOnInit(): void {
    this.task$ = this.activatedRoute.data.pipe(
      map((data) => data['task']),
      switchMap((task) => this.tasksService.subscribeToTaskUpdates(task.id).pipe(startWith(task))),
    );

    this.canOpenTaskWizard$ = this.task$.pipe(
      map((task) => {
        return (
          task.status === TaskStatus.NotStarted ||
          task.status === TaskStatus.InProgress ||
          task.status === TaskStatus.Completed
        );
      }),
    );
  }

  close(): void {
    this.store.dispatch(new Navigate(['..'], {}, { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }));
  }
}
