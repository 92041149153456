import type { SortingOptions } from '@clover/core/helpers/paging';
import { Selector } from '@ngxs/store';
import type { ProductPreview, ProductsStateModel, ProductWorklist } from './products-state.model';
import type { ProductsSortingProperty } from './products.service';
import { ProductsState } from './products.state';

export class ProductsSelectors {
  @Selector([ProductsState])
  static worklists(state: ProductsStateModel): ProductWorklist[] {
    return state.worklists;
  }

  @Selector([ProductsState])
  static products(state: ProductsStateModel): ProductPreview[] {
    return state.products?.data || [];
  }

  @Selector([ProductsState])
  static productsCount(state: ProductsStateModel): number {
    return state.products?.total || 0;
  }

  @Selector([ProductsState])
  static productsLoadingStatus(state: ProductsStateModel): 'void' | 'loading' | 'loaded' | 'error' | 'loading-next' {
    return state.loadingStatus;
  }

  @Selector([ProductsState])
  static productsQuery(state: ProductsStateModel): string {
    return state.query;
  }

  @Selector([ProductsState])
  static productsWorklistId(state: ProductsStateModel): number | undefined {
    return state.worklistId;
  }

  @Selector([ProductsState])
  static productsWorklist(state: ProductsStateModel): ProductWorklist | undefined {
    return state.worklists?.find((worklist) => worklist.id === state.worklistId);
  }

  @Selector([ProductsState])
  static productsSortingOptions(state: ProductsStateModel): SortingOptions<ProductsSortingProperty> {
    return state.sorting;
  }
}
