import { Component, Input, type OnInit, ViewEncapsulation, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '@core/services/config.service';
import { AuthService } from '../../auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@UntilDestroy()
@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective, ButtonLoadingDirective, DatePipe, TranslateModule],
})
export class TermsModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly auth = inject(AuthService);
  @Input() readonly = false;
  @Input() authToken: string;

  public isLoading = false;
  public termsHtml: string;

  public get updatedDate(): Date {
    return new Date(ConfigService.settings.touUpdateDate);
  }

  public ngOnInit(): void {
    fetch(`${ConfigService.settings.assetsBaseUrl}assets/i18n/terms-en.html`)
      .then(async (res) => await res.text())
      .then((text) => (this.termsHtml = text));
  }

  public acceptTerms(): void {
    this.auth
      .acceptToU(this.authToken)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.activeModal.close();
      });
  }

  public declineTerms(): void {
    this.activeModal.dismiss();
  }
}
