import { TaskStatus } from '@clover/conversations-v4/tasks/tasks.model';
import { BPIInvoice, BPIOrder, BPIPayment } from '@design/overlays/business-object-modal/business-object';

export interface Company {
  id: number;
  name: string;
  logo: string;
  quickLinks: CompanyQuickLink[];
  statistics: CompanyStatistic[];
  tasks: CompanyTask[];
  orders: BPIOrder[];
  invoices: BPIInvoice[];
  payments: BPIPayment[];
}

export interface CompanyQuickLink {
  logo: string;
  description: string;
  link: string;
}

export interface CompanyStatistic {
  title: string;
  description: string;
  value: string;
  increase: boolean;
  arrowDown?: boolean;
}

export interface CompanyTask {
  id: number;
  title: string;
  description: string;
  status: TaskStatus;
  assignee: {
    name: string;
    avatar: string;
  };
  assigner: {
    name: string;
    avatar: string;
  };
  assignedAt: string;
  dueDate: string;
}

export const DEFAULT_QUICK_LINKS: CompanyQuickLink[] = [];
export const RETAIL_QUICK_LINKS: CompanyQuickLink[] = [
  {
    logo: 'assets/demo/living-spaces/mirakl-logo.png',
    description: 'Access and manage marketplace listings',
    link: 'https://mirakl.com/',
  },
  {
    logo: 'assets/demo/living-spaces/asana-logo.png',
    description: 'Track and manage project tasks and deadlines',
    link: 'https://asana.com/',
  },
  {
    logo: 'assets/demo/living-spaces/qarma-logo.png',
    description: 'Perform quality control inspections and reporting',
    link: 'https://qarmainspect.com/',
  },
  {
    logo: 'assets/demo/living-spaces/in-river-logo.png',
    description: 'Manage product information and digital assets',
    link: 'https://inriver.com/',
  },
  {
    logo: 'assets/demo/living-spaces/true-commerce-logo.png',
    description: 'Electronic data interchange for order processing',
    link: 'https://truecommerce.com/',
  },
  {
    logo: 'assets/demo/living-spaces/clover-logo.png',
    description: 'New Supplier Onboarding',
    link: 'https://clovercollab.com/',
  },
  {
    logo: 'assets/demo/living-spaces/clover-logo.png',
    description: 'Update Contact Information',
    link: 'https://clovercollab.com/',
  },
];

export const DEFAULT_STATISTICS: CompanyStatistic[] = [
  {
    title: 'Total Revenue',
    description: 'Monthly Avg YTD',
    value: '$2.5 M',
    increase: true,
    arrowDown: false,
  },
  {
    title: 'R&D Investment',
    description: 'MTD',
    value: '$300 K',
    increase: true,
    arrowDown: false,
  },
  {
    title: 'Product Launches',
    description: 'YTD',
    value: '5',
    increase: false,
    arrowDown: true,
  },
  {
    title: 'Customer Satisfaction',
    description: 'YTD',
    value: '90%',
    increase: true,
    arrowDown: false,
  },
  {
    title: 'Employee Turnover',
    description: 'YTD',
    value: '8%',
    increase: false,
    arrowDown: false,
  },
  {
    title: 'Market Share Growth',
    description: 'YTD',
    value: '12%',
    increase: true,
    arrowDown: false,
  },
];
export const RETAIL_STATISTICS: CompanyStatistic[] = [
  {
    title: 'Total Order Value',
    description: 'Monthly Avg YTD',
    value: '$1.22 M',
    increase: false,
    arrowDown: true,
  },
  {
    title: 'Chargeback Total ',
    description: 'MTD',
    value: '$45 K',
    increase: false,
    arrowDown: false,
  },
  {
    title: 'On Time Delivery',
    description: 'YTD',
    value: '80%',
    increase: false,
    arrowDown: true,
  },
  {
    title: 'Total Invoice Value',
    description: 'MTD',
    value: '$1.08 M',
    increase: false,
    arrowDown: true,
  },
  {
    title: 'Invoice Accuracy',
    description: 'YTD',
    value: '95%',
    increase: false,
    arrowDown: true,
  },
  {
    title: 'Average Defect Rate',
    description: 'YTD',
    value: '2.6%',
    increase: true,
    arrowDown: true,
  },
  {
    title: 'Order Accuracy (%)',
    description: 'YTD',
    value: '98%',
    increase: true,
    arrowDown: false,
  },
  {
    title: 'Order Fill Rate (%)',
    description: 'YTD',
    value: '96%',
    increase: true,
    arrowDown: false,
  },
  {
    title: 'Sustainability Goals',
    description: 'YTD PROGRESS',
    value: '50%',
    increase: true,
    arrowDown: false,
  },
  {
    title: 'Product Reviews',
    description: 'AVG RATING YTD',
    value: '4.4',
    increase: true,
    arrowDown: false,
  },
  {
    title: 'Service Ratings',
    description: 'AVG RATING YTD',
    value: '3.7',
    increase: false,
    arrowDown: true,
  },
  {
    title: 'Task Response Time',
    description: 'AVG DAYS YTD',
    value: '1.2',
    increase: true,
    arrowDown: true,
  },
];

export const DEFAULT_TASKS: CompanyTask[] = [
  {
    id: 11345,
    title: 'Project Alpha',
    description: 'Project Alpha description',
    status: TaskStatus.InProgress,
    assignee: {
      name: 'Alice Smith',
      avatar: 'https://picsum.photos/seed/12df/200',
    },
    assigner: {
      name: 'Bob Johnson',
      avatar: 'https://picsum.photos/seed/43as/200',
    },
    assignedAt: '2023-03-15',
    dueDate: '2023-12-31',
  },
  {
    id: 11346,
    title: 'Project Beta',
    description: 'Project Beta description',
    status: TaskStatus.InProgress,
    assignee: {
      name: 'Charlie Brown',
      avatar: 'https://picsum.photos/seed/89df/200',
    },
    assigner: {
      name: 'David Miller',
      avatar: 'https://picsum.photos/seed/342k/200',
    },
    assignedAt: '2023-05-20',
    dueDate: '2024-02-28',
  },
];
export const RETAIL_TASKS: CompanyTask[] = [
  {
    id: 11342,
    title: 'Order #1234',
    description: 'Order #1234 description',
    status: TaskStatus.Completed,
    assignee: {
      name: 'John Doe',
      avatar: 'https://picsum.photos/seed/wefv2/200',
    },
    assigner: {
      name: 'Jane Doe',
      avatar: 'https://picsum.photos/seed/3gzs/200',
    },
    assignedAt: '2023-01-01',
    dueDate: '2024-07-01',
  },
  {
    id: 11343,
    title: 'Order #1235',
    description: 'Order #1235 description',
    status: TaskStatus.InProgress,
    assignee: {
      name: 'John Doe',
      avatar: 'https://picsum.photos/seed/2asdffg/200',
    },
    assigner: {
      name: 'Jane Doe',
      avatar: 'https://picsum.photos/seed/gdf3/200',
    },
    assignedAt: '2023-01-01',
    dueDate: '2023-01-01',
  },
  {
    id: 11344,
    title: 'Order #1236',
    description: 'Order #1236 description',
    status: TaskStatus.Closed,
    assignee: {
      name: 'John Doe',
      avatar: 'https://picsum.photos/seed/2da/200',
    },
    assigner: {
      name: 'Jane Doe',
      avatar: 'https://picsum.photos/seed/ad3/200',
    },
    assignedAt: '2023-01-01',
    dueDate: '2023-01-01',
  },
];

export interface Invitation {
  id: number;
  companyName: string;
  companyLogo: string;
  date: string;
}

export const invitations: Invitation[] = [
  {
    id: 1,
    companyName: 'Insurance GTC',
    companyLogo: 'https://picsum.photos/seed/asd123/200',
    date: '2023-01-01',
  },
  {
    id: 2,
    companyName: 'Tech Solutions',
    companyLogo: 'https://picsum.photos/seed/78da9/200',
    date: '2023-01-01',
  },
];
