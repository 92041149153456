import type { SortingOptions } from '@clover/core/helpers/paging';
import type { CampaignPreview, FilterableCampaignStatus } from './campaigns-state.model';
import type { CampaignsSortingProperty } from './campaigns.service';
import type { StateOperator } from '@ngxs/store';

export class LoadCampaigns {
  static readonly type = '[Campaigns] Load Campaigns';

  constructor(
    public payload: {
      query?: string;
      status?: FilterableCampaignStatus;
      sortingOptions?: SortingOptions<CampaignsSortingProperty>;
    },
  ) {}
}

export class LoadNextCampaigns {
  static readonly type = '[Campaigns] Load Next Campaigns';
}

export class PatchCampaign {
  static readonly type = '[Campaigns] Patch campaign';

  constructor(
    public campaignId: number,
    public patch: StateOperator<CampaignPreview>,
  ) {}
}

export class RemoveCampaign {
  static readonly type = '[Campaigns] Remove campaign';

  constructor(public campaignId: number) {}
}
