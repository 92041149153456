import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Injector,
  input,
  model,
  output,
  type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { ProductPreview, type Product } from '@clover/products-v2/state/products-state.model';
import { mapProductToProductPreview, ProductsService } from '@clover/products-v2/state/products.service';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { DropdownTriggerDirective } from '@design/overlays/dropdown/dropdown-trigger.directive';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TdComponent } from '@design/table/td/td.component';
import { ButtonComponent } from '../../../../../stories/buttons/button/button.component';
import { CheckboxComponent } from '../../../../../stories/forms/checkbox/checkbox.component';
import { DropdownActionComponent } from '../../../../../stories/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '../../../../../stories/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownComponent } from '../../../../../stories/overlays/dropdown/dropdown.component';
import { ProductAccessChipComponent } from './product-access-chip/product-access-chip.component';

@Component({
  selector: 'cc-product-table-row',
  standalone: true,
  imports: [
    TdComponent,
    CheckboxComponent,
    ProductAccessChipComponent,
    ButtonComponent,
    TooltipDirective,
    DropdownTriggerDirective,
    DropdownComponent,
    DropdownActionComponent,
    DropdownDividerComponent,
    RouterLink,
  ],
  templateUrl: './product-table-row.component.html',
  styleUrl: './product-table-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTableRowComponent implements OnInit {
  product = model.required<ProductPreview>();

  showCheckbox = input<boolean>(false);
  checked = input<boolean>(false);

  checkboxChange = output<Event>();
  assignTask = output<void>();
  delete = output<void>();

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly productsService = inject(ProductsService);
  private readonly injector = inject(Injector);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.productsService
      .subscribeToProductUpdates(this.product().id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((product: Product): void => {
        this.product.set(mapProductToProductPreview(product));
      });
  }

  async confirmDelete(): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: 'Are you sure you want to delete this product?',
        description:
          'This action cannot be undone. Deleting a product will remove it from all lists and campaigns it is associated with.',
        confirmActionText: 'Delete product',
        cancelActionText: 'Cancel',
        destructive: true,
        style: 'default',
      })) === 'confirm';

    if (confirmed) this.delete.emit();
  }
}
