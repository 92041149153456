import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import {
  mapTaskPerformerCompany,
  type TaskPerformerCompanyResponse,
} from '@clover/conversations-v4/tasks/tasks.service';
import {
  getOffsetPagingOptionsParams,
  PagingOrder,
  type OffsetPagingOptions,
  type OffsetPagingWrapper,
} from '@clover/core/helpers/paging';
import { ccPreventDuplicateSubscriptions } from '@clover/core/helpers/prevent-duplicate-subscriptions';
import { CdkPortalService } from '@clover/core/services/cdk-portal.service';
import { HttpService } from '@clover/core/services/http.service';
import { ToastType } from '@design/overlays/toast/toast';
import { Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, filter, forkJoin, map, of, Subject, tap, type Observable, type Subscription } from 'rxjs';
import { AudienceService } from './audience.service';
import { CampaignPreview, CampaignStatus, FilterableCampaignStatus, type Campaign } from './campaigns-state.model';
import { PatchCampaign, RemoveCampaign } from './campaigns.actions';

export interface CampaignResponse {
  id: number;
  name: string;
  description: string;
  status: CampaignStatus;
  ownerUserId: number;
  workflowId: number;
  workflowName: string;
  workflowSnapshotId: string;
  publishedAt: string;
  createdAt: string;
  completionRate: number;
}

export interface CampaignPreviewResponse {
  id: number;
  name: string;
  status: CampaignStatus;
  audienceCompany: TaskPerformerCompanyResponse | undefined;
  audienceCompaniesCount: number;
  publishedAt: string | undefined;
  createdAt: string;
  completionRate: number | undefined;
}

export function mapCampaign(
  c: CampaignResponse,
  previewCompany: TaskPerformerCompanyResponse | undefined,
  companiesCount: number,
): Campaign {
  return {
    id: c.id,
    name: c.name,
    description: c.description,
    status: c.status,
    completion: c.completionRate,
    ownerId: c.ownerUserId,
    workflow: {
      id: c.workflowId,
      name: c.workflowName,
      snapshotId: c.workflowSnapshotId,
    },
    previewCompany: previewCompany ? mapTaskPerformerCompany(previewCompany) : undefined,
    companiesCount,
    deliveredAt: c.publishedAt,
    createdAt: c.createdAt,
  };
}

export function mapCampaignPreview(c: CampaignPreviewResponse): CampaignPreview {
  return {
    id: c.id,
    name: c.name,
    status: c.status,
    completion: c.completionRate || 0,
    previewCompany: c.audienceCompany ? mapTaskPerformerCompany(c.audienceCompany) : undefined,
    companiesCount: c.audienceCompaniesCount,
    deliveredAt: c.publishedAt || undefined,
    createdAt: c.createdAt,
  };
}

export type CampaignsSortingProperty = 'name' | 'publishedAt' | 'status' | 'completionRate';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);

  private readonly audienceService = inject(AudienceService);
  private readonly portalService = inject(CdkPortalService);
  private readonly store = inject(Store);

  private _campaignUpdated$ = new Subject<Campaign>();

  subscribeToCampaignUpdates(id: number): Observable<Campaign> {
    return this._campaignUpdated$.asObservable().pipe(filter((campaign) => campaign.id === id));
  }

  getCampaign(id: number): Observable<Campaign> {
    const campaignUrlTree = this.router.createUrlTree(['api', 'userTaskCampaigns'], {
      queryParams: {
        id,
      },
    });
    const campaignPath = this.serializer.serialize(campaignUrlTree);

    return forkJoin([
      this.http.getV2<CampaignResponse>(campaignPath),

      // Need to load first company to match Campaign model structure with CampaignPreview model
      this.audienceService.searchCampaignAudience(id, undefined, {
        offset: 0,
        limit: 1,
        orderBy: 'name',
        order: PagingOrder.Ascending,
      }),
    ]).pipe(
      map(([campaignResponse, audience]) => {
        const previewCompany = audience.data[0].assigneeCompany;
        const companiesCount = audience.count;
        return mapCampaign(campaignResponse, previewCompany, companiesCount);
      }),
    );
  }

  searchCampaigns(
    query: string,
    status: FilterableCampaignStatus,
    pagingOptions?: OffsetPagingOptions<CampaignsSortingProperty>,
  ): Observable<OffsetPagingWrapper<CampaignPreview>> {
    const urlTree = this.router.createUrlTree(['api', 'userTaskCampaigns', 'search'], {
      queryParams: {
        name: query || undefined,
        status: status === FilterableCampaignStatus.All ? undefined : status,
        ...getOffsetPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<OffsetPagingWrapper<CampaignPreviewResponse>>(path).pipe(
      map((response) => ({
        ...response,
        data: response.data.map(mapCampaignPreview),
      })),
    );
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  sendReminder(campaignId: number): Subscription {
    return this.http
      .postV2<void>(`api/userTaskCampaigns/${campaignId}/remind`, {})
      .pipe(
        tap(() => {
          this.portalService.presentToast('Reminder sent', ToastType.Success);
        }),
        catchError((error) => {
          this.portalService.presentToast('Failed to send reminder', ToastType.Error);
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  closeCampaign(campaignId: number): Subscription {
    return this.http
      .postV2<void>(`api/userTaskCampaigns/${campaignId}/close`, {})
      .pipe(
        tap(() => {
          this.portalService.presentToast('Campaign closed', ToastType.Success);
          this.requestCampaignUpdate(campaignId);
          this.store.dispatch(
            new PatchCampaign(
              campaignId,
              patch({
                status: CampaignStatus.Closed,
              }),
            ),
          );
        }),
        catchError((error) => {
          this.portalService.presentToast('Failed to close campaign', ToastType.Error);
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  publishDraftCampaign(campaignId: number): Subscription {
    return this.http
      .postV2<void>(`api/userTaskCampaigns/${campaignId}/publish`, {})
      .pipe(
        tap(() => {
          this.portalService.presentToast('Campaign published', ToastType.Success);
          this.requestCampaignUpdate(campaignId);
          this.store.dispatch(
            new PatchCampaign(
              campaignId,
              patch({
                status: CampaignStatus.InProgress,
              }),
            ),
          );
        }),
        catchError((errorResponse) => {
          const errorMessage = (errorResponse?.error?.errors[0].errorMessage as string)?.replaceAll('`', '"') || '';
          this.portalService.presentToast(errorMessage || 'Failed to publish campaign', ToastType.Error);
          return of(errorResponse);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  deleteDraftCampaign(campaignId: number): Subscription {
    return this.http
      .deleteV2(`api/userTaskCampaigns/${campaignId}`)
      .pipe(
        tap(() => {
          this.portalService.presentToast('Campaign deleted', ToastType.Success);
          this.store.dispatch(new RemoveCampaign(campaignId));
        }),
        catchError((errorResponse) => {
          this.portalService.presentToast('Failed to delete campaign', ToastType.Error);
          return of(errorResponse);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('last-wins')
  private requestCampaignUpdate(campaignId: number): Subscription {
    return this.getCampaign(campaignId).subscribe((campaign) => this._campaignUpdated$.next(campaign));
  }
}
