import { CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { TaskType, FilterableTaskStatus } from '@clover/conversations-v4/tasks/tasks.model';
import { Store } from '@ngxs/store';
import * as R from 'ramda';
import { TasksSelectors } from './state/tasks.selectors';
import { inject } from '@angular/core';

export enum TaskTypeRouteParam {
  Received = 'received',
  Sent = 'sent',
}

export enum TaskStatusRouteParam {
  All = 'all',
  Incomplete = 'incomplete',
  Completed = 'completed',
  Closed = 'closed',
  NotStarted = 'not-started',
  InProgress = 'in-progress',
  NotConnected = 'not-connected',
  WaitingForApproval = 'waiting-for-approval',
}

export const taskTypeRoutesMap = new Map<TaskTypeRouteParam, TaskType>([
  [TaskTypeRouteParam.Received, TaskType.Received],
  [TaskTypeRouteParam.Sent, TaskType.Sent],
]);

export const taskStatusRoutesMap = new Map<TaskStatusRouteParam, FilterableTaskStatus>([
  [TaskStatusRouteParam.All, FilterableTaskStatus.All],
  [TaskStatusRouteParam.Incomplete, FilterableTaskStatus.Incomplete],
  [TaskStatusRouteParam.Completed, FilterableTaskStatus.Completed],
  [TaskStatusRouteParam.Closed, FilterableTaskStatus.Closed],
  [TaskStatusRouteParam.NotStarted, FilterableTaskStatus.NotStarted],
  [TaskStatusRouteParam.InProgress, FilterableTaskStatus.InProgress],
  [TaskStatusRouteParam.NotConnected, FilterableTaskStatus.NotConnected],
  [TaskStatusRouteParam.WaitingForApproval, FilterableTaskStatus.WaitingForApproval],
]);

export const defaultTaskType: TaskType = TaskType.Received;
export const defaultTaskStatus: FilterableTaskStatus = FilterableTaskStatus.Incomplete;

export const tasksListGuard: CanActivateFn = (route) => {
  const {
    queryParams: { query: queryParam, type: typeParam, status: statusParam },
  } = route;

  const [queryValid, query] = validateQueryParam(queryParam);
  const [typeValid, type] = validateTypeParam(typeParam);
  const [statusValid, status] = validateStatusParam(statusParam);

  if (queryValid && typeValid && statusValid) return true;

  const segmentParams = {
    query,
    type,
    status,
  };

  // Remove undefined parameters
  const definedParams = R.pickBy((value) => !!value, segmentParams);

  return createUrlTreeFromSnapshot(route, [], definedParams);
};

function validateQueryParam(param: string | undefined): [boolean, string] {
  return [true, param || inject(Store).selectSnapshot(TasksSelectors.tasksQuery)];
}

function validateTypeParam(param: TaskTypeRouteParam | undefined): [boolean, string] {
  const valid = taskTypeRoutesMap.has(param);
  return [valid, valid ? param : taskTypeToRouteParam(inject(Store).selectSnapshot(TasksSelectors.tasksType))];
}

function validateStatusParam(param: TaskStatusRouteParam | undefined): [boolean, string] {
  const valid = taskStatusRoutesMap.has(param);
  return [valid, valid ? param : taskStatusToRouteParam(inject(Store).selectSnapshot(TasksSelectors.tasksStatus))];
}

export function taskTypeToRouteParam(type: TaskType): TaskTypeRouteParam {
  for (const [param, value] of taskTypeRoutesMap) {
    if (value === type) return param;
  }

  return taskTypeToRouteParam(defaultTaskType);
}

export function taskStatusToRouteParam(status: FilterableTaskStatus): TaskStatusRouteParam {
  for (const [param, value] of taskStatusRoutesMap) {
    if (value === status) return param;
  }

  return taskStatusToRouteParam(defaultTaskStatus);
}
