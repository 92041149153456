import { NgForOf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DateFormatDistancePipe } from '@clover/core/pipes/date-format.pipe';
import { Invitation, invitations } from '@clover/dashboard-v2/mocks';
import { CoreModule } from '@core/core.module';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TableComponent } from '@design/table/table.component';
import { ThComponent } from '@design/table/th/th.component';

@Component({
  selector: 'cc-invitations',
  standalone: true,
  imports: [
    TableComponent,
    ThComponent,
    ButtonComponent,
    NgForOf,
    CoreModule,
    NgOptimizedImage,
    TooltipDirective,
    DateFormatDistancePipe,
  ],
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationsComponent implements OnInit {
  protected invitations: Invitation[] = [];

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  ngOnInit(): void {
    this.invitations = invitations;
  }

  declineInvitation(invitation: Invitation): void {
    this.invitations = this.invitations.filter((i) => i.id !== invitation.id);
  }

  acceptInvitation(invitation: Invitation): void {
    this.invitations = this.invitations.filter((i) => i.id !== invitation.id);
  }
}
