import { EMPTY_PAGING, PagingOrder, type OffsetPagingWrapper, type SortingOptions } from '@clover/core/helpers/paging';
import type { CampaignsSortingProperty } from './campaigns.service';
import type {
  TaskCommunicationRole,
  TaskPerformer,
  TaskPerformerCompany,
} from '@clover/conversations-v4/tasks/tasks.model';

export interface Campaign {
  id: number;
  name: string;
  description: string;
  status: CampaignStatus;
  ownerId: number;
  workflow: CampaignWorkflow;
  previewCompany: TaskPerformerCompany | undefined;
  companiesCount: number;
  deliveredAt: string;
  createdAt: string;
  completion: number;
}

export type CampaignPreview = Pick<
  Campaign,
  'id' | 'name' | 'status' | 'previewCompany' | 'companiesCount' | 'completion' | 'deliveredAt' | 'createdAt'
>;

export interface CampaignWorkflow {
  id: number;
  name: string;
  snapshotId: string;
}

export interface CampaignAudience {
  id: number;
  name: string;
  status: CampaignStatus;
  completion: number;
  assigneeRoles: TaskCommunicationRole[];
  assigneeUsers: TaskPerformer[];
  assigneeCompany: TaskPerformerCompany;
  assignedAt: string;
}

export enum CampaignStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  Closed = 'Closed',
  Draft = 'Draft',
}

export enum FilterableCampaignStatus {
  All = 'All',
  Completed = 'Completed',
  InProgress = 'InProgress',
  Closed = 'Closed',
  Draft = 'Draft',
}

export interface CampaignsStateModel {
  campaigns: OffsetPagingWrapper<CampaignPreview>;
  loadingStatus: 'void' | 'loading' | 'loaded' | 'error' | 'loading-next';
  query: string;
  status: FilterableCampaignStatus;
  sorting: SortingOptions<CampaignsSortingProperty>;
}

export const defaultCampaignsState: CampaignsStateModel = {
  campaigns: EMPTY_PAGING,
  loadingStatus: 'void',
  query: '',
  status: FilterableCampaignStatus.All,
  sorting: {
    orderBy: 'name',
    order: PagingOrder.Ascending,
  },
};
