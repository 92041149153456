<cc-table
  [ccEmptyRowTemplate]="emptyRowTemplate"
  [ccSearchPlaceholder]="'Search for a payment...'"
  ccPadding="medium"
>
  <ng-container slot="cc-table-title"> Payments</ng-container>

  <ng-container slot="cc-table">
    <thead>
      <tr>
        <th ccTh>Payment #</th>
        <th ccTh>Sender's Company</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let payment of payments">
        <tr ccTr>
          <td>
            <div class="payment-column">
              <div class="payment-column__details">
                <div class="payment-column__details-title">{{ payment.id }}</div>

                <div
                  [style.background-color]="getStatusBgColor(payment)"
                  [style.color]="getStatusFgColor(payment)"
                  class="payment-column__details-status"
                >
                  {{ getStatusText(payment) }}
                </div>
              </div>

              <div class="payment-column__description">Amount: {{ payment.amount | currency : 'USD' }}</div>
            </div>
          </td>

          <td ccTd>
            <div class="performer-column">
              <img
                [alt]="payment.sender"
                [ccTooltip]="payment.sender"
                src="https://picsum.photos/seed/{{ payment.id }}/200"
              />

              <div class="performer-column__name">{{ payment.sender }}</div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </ng-container>
</cc-table>

<ng-template #emptyRowTemplate> There are no payments to display.</ng-template>
