import { NgForOf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CompanyQuickLink } from '@clover/dashboard-v2/mocks';

@Component({
  selector: 'cc-company-quick-links',
  standalone: true,
  templateUrl: './company-quick-links.component.html',
  styleUrls: ['./company-quick-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgForOf, NgOptimizedImage, RouterLink],
})
export class CompanyQuickLinksComponent {
  @Input()
  quickLinks: CompanyQuickLink[] = [];
}
