import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ModalService as LegacyModalService } from '@clover/core/services/modal.service';
import { ImportProductsModalComponent } from '@clover/products/modals/import-products-modal/import-products-modal.component';
import { Store } from '@ngxs/store';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbar } from 'ngx-scrollbar';
import { LoadNextProducts, LoadProducts } from '../state/products.actions';
import { ProductsHeaderComponent } from './products-header/products-header.component';
import { ProductsTableComponent } from './products-table/products-table.component';

@Component({
  selector: 'cc-products-list',
  standalone: true,
  imports: [ProductsHeaderComponent, ProductsTableComponent, NgScrollbar, InfiniteScrollDirective, CdkScrollable],
  templateUrl: './products-list.component.html',
  styleUrl: './products-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsListComponent {
  private readonly store = inject(Store);
  private readonly modalService = inject(LegacyModalService);

  loadNextProducts(): void {
    this.store.dispatch(new LoadNextProducts());
  }

  async handleProductsImport(): Promise<void> {
    let worklistId: number | undefined = undefined;

    try {
      const result = await this.modalService.open({
        content: ImportProductsModalComponent,
        options: {
          size: 'xl',
          backdrop: 'static',
        },
      }).result;

      worklistId = result['worklistId'];
      // eslint-disable-next-line no-empty
    } catch {}

    this.store.dispatch(new LoadProducts({ worklistId }));
  }
}
