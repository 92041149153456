import { provideStates } from '@ngxs/store';
import { workflowsListGuard } from './workflows-list.guard';
import { workflowsListResolver } from './workflows-list.resolver';
import type { Routes } from '@angular/router';
import { WorkflowsPageComponent } from './workflows-page.component';
import { WorkflowsState } from './state/workflows.state';
import { WorkflowsListComponent } from './workflows-list/workflows-list.component';
import { PermissionsGuard } from '@clover/core/guards/permissions.guard';

export const WORKFLOWS_BASE_URL = 'workflows';

export const workflowsPageRoutes: Routes = [
  {
    path: '',
    providers: [provideStates([WorkflowsState])],
    component: WorkflowsPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        resolve: {
          workflowsLoaded: workflowsListResolver,
        },
        data: {
          permissions: ['Workflow_View'],
        },
        canActivate: [PermissionsGuard, workflowsListGuard],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        component: WorkflowsListComponent,
      },
    ],
  },
];
