import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
  type OnInit,
  ViewEncapsulation,
  inject,
  output,
} from '@angular/core';
import { type AppNotification } from '../../models/notification';
import { NotificationService } from '../../services/notification.service';
import { SignalrService } from '../../services/signalr.service';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { Subject } from 'rxjs';
import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserLogoComponent } from '@core/components/user-logo/user-logo.component';

import { CompanyLogoComponent } from '@core/components/company-logo/company-logo.component';
import { DateFormatDistancePipe } from '@core/pipes/date-format.pipe';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

const NOTIFICATION_PAGE_SIZE = 5;

@Component({
  selector: 'app-notifications-dropdown-menu',
  templateUrl: './notifications-dropdown-menu.component.html',
  styleUrls: ['./notifications-dropdown-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ScrollableAreaComponent,
    TranslateModule,
    UserLogoComponent,
    CompanyLogoComponent,
    DateFormatDistancePipe,
    AssetSrcDirective,
    FocusableDirective,
  ],
})
export class NotificationsDropdownMenuComponent implements OnInit, OnDestroy {
  private readonly userService = inject(UserService);
  private readonly notificationService = inject(NotificationService);
  private readonly signalR = inject(SignalrService);
  loadedNotifications: AppNotification[] = [];
  totalNotifications: number = 0;
  isLoadingNotifications = false;

  notificationsToggle = output<void>();

  hasNotificationsChange = output<boolean>();

  private readonly destroyed$: Subject<void> = new Subject<void>();

  get totalNotificationsNumber(): string {
    if (!this.totalNotifications) {
      return '0';
    }
    return this.totalNotifications > 99 ? '99+' : `${this.totalNotifications}`;
  }

  get isImpersonating(): boolean {
    return this.userService.isImpersonating;
  }

  ngOnInit(): void {
    this.userService.profile$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      if (!user) {
        return;
      }
      this.loadedNotifications = [];
      this.totalNotifications = 0;
      this.loadNotifications();
      this.hasNotificationsChange.emit(this.totalNotifications > 0);
    });

    this.signalR.notifications$.pipe(takeUntil(this.destroyed$)).subscribe((notification) => {
      this.loadedNotifications.unshift(notification);
      this.totalNotifications++;
      this.hasNotificationsChange.emit(this.totalNotifications > 0);
    });
  }

  onNotificationsScroll(): void {
    if (!this.isLoadingNotifications && this.loadedNotifications.length < this.totalNotifications) {
      this.loadNotifications();
    }
  }

  dismissNotification(notification: AppNotification): void {
    this.notificationService.dismissNotification(notification.id).then(() => {
      this.loadedNotifications = this.loadedNotifications.filter((n) => n.id !== notification.id);
      this.totalNotifications--;
      if (this.totalNotifications === 0) {
        this.notificationsToggle.emit();
      }
      this.hasNotificationsChange.emit(this.totalNotifications > 0);
    });
  }

  dismissAllNotifications(): void {
    this.notificationService.dismissAllNotifications().then(() => {
      this.loadedNotifications = [];
      this.totalNotifications = 0;
      this.hasNotificationsChange.emit(this.totalNotifications > 0);
      this.notificationsToggle.emit();
    });
  }

  loadNotifications(): void {
    this.isLoadingNotifications = true;
    this.notificationService
      .loadNotifications(this.loadedNotifications.length, NOTIFICATION_PAGE_SIZE)
      .then((res) => {
        this.totalNotifications = res.total;
        this.hasNotificationsChange.emit(this.totalNotifications > 0);
        this.loadedNotifications = this.loadedNotifications.concat(res.data);
      })
      .finally(() => (this.isLoadingNotifications = false));
  }

  getNotificationText(notification: AppNotification): string {
    if (notification?.metadata?.elements?.length === 0) {
      return notification.message;
    }

    let msg = notification.message;
    notification.metadata.elements.forEach((el) => {
      msg = msg.replace(
        el.Tag,
        `<a href="${window.location.origin}/conversations?userId=${el.TargetUserId}">${el.Label}</a>`,
      );
    });

    return msg;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
