<div
        class="global-search"
        [class.global-search--has-input]="searchValue">

    <div class="global-search__input">
        <div class="global-search__select">
            <div class="global-search__select-value">
                {{ (event | async) || 'Company' }}
            </div>

            <i class="icon-expand">
            </i>
        </div>

        <input
            #input
            id="globalSearchInput"
            class="global-search__input-field"
            type="text"
            placeholder="{{ 'networkPage.searchForCompany' | translate }}"
            [disabled]="disabled"
            [(ngModel)]="searchValue"
            (keydown.enter)="onEnterKeydown()"
        />
    </div>
</div>
