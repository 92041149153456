<div class="company-quick-links">
  <div class="company-quick-links__header">Quick Links</div>

  <div class="company-quick-links__list">
    <ng-container *ngFor="let link of quickLinks">
      <a
        [href]="link.link"
        class="company-quick-links__list-item"
      >
        <img
          [ngSrc]="link.logo"
          alt=""
          class="company-quick-links__list-item-logo"
          height="200"
          width="200"
        />

        <div class="company-quick-links__list-item-description">{{ link.description }}</div>
      </a>
    </ng-container>
  </div>
</div>
