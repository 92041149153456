import { ResolveFn } from '@angular/router';
import { LoadCampaigns } from './state/campaigns.actions';
import { campaignStatusRoutesMap } from './campaigns-list.guard';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, map, of } from 'rxjs';

export const campaignsListResolver: ResolveFn<boolean> = (route) => {
  const { query = '', status: statusRoute } = route.queryParams;

  const status = campaignStatusRoutesMap.get(statusRoute);

  const store = inject(Store);
  return store.dispatch(new LoadCampaigns({ query, status })).pipe(
    map(() => true),
    catchError(() => of(false)),
  );
};
