import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { CompanyInvoicesComponent } from '@clover/dashboard-v2/company-invoices/company-invoices.component';
import { CompanyOrdersComponent } from '@clover/dashboard-v2/company-orders/company-orders.component';
import { CompanyPaymentsComponent } from '@clover/dashboard-v2/company-payments/company-payments.component';
import { CompanyQuickLinksComponent } from '@clover/dashboard-v2/company-quick-links/company-quick-links.component';
import { CompanyStatsComponent } from '@clover/dashboard-v2/company-stats/company-stats.component';
import { CompanyTasksComponent } from '@clover/dashboard-v2/company-tasks/company-tasks.component';
import { InvitationsComponent } from '@clover/dashboard-v2/invitations/invitations.component';
import { Company, CompanyTask } from '@clover/dashboard-v2/mocks';
import { CompanyWikiComponent } from '@clover/network-v2/company/company-wiki/company-wiki.component';
import { LoadDocuments } from '@clover/network-v2/company/state/wiki/wiki.actions';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { UserService } from '@core/services/user.service';
import { PageWithSubnavComponent } from '@design/layout/page-with-subnav/page-with-subnav.component';
import type { BPIInvoice, BPIOrder } from '@design/overlays/business-object-modal/business-object';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DropdownActionComponent } from '../../stories/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '../../stories/overlays/dropdown/dropdown-divider/dropdown-divider.component';

@UntilDestroy()
@Component({
  selector: 'cc-dashboard',
  standalone: true,
  imports: [
    PageWithSubnavComponent,
    NgScrollbarModule,
    CdkScrollableModule,
    NgOptimizedImage,
    InvitationsComponent,
    CompanyStatsComponent,
    NgIf,
    OverlayModule,
    CompanyTasksComponent,
    CompanyOrdersComponent,
    CompanyInvoicesComponent,
    CompanyPaymentsComponent,
    CompanyWikiComponent,
    CompanyQuickLinksComponent,
    DropdownComponent,
    DropdownDividerComponent,
    DropdownActionComponent,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  protected activeCompanyView: Company | undefined = undefined;

  protected companies: Company[] = [];

  protected hideHeaderBorder = true;

  protected dropdownOpen = false;
  protected readonly dropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: 8,
    },
  ];
  // protected readonly companies = companies;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly userService = inject(UserService);
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  get greeting(): string {
    const now = new Date();
    const currentHour = now.getHours();

    if (currentHour < 12) {
      return 'Good morning';
    } else if (currentHour < 18) {
      return 'Good afternoon';
    } else if (currentHour < 22) {
      return 'Good evening';
    } else {
      return 'Good night';
    }
  }

  get userName(): string {
    return this.userService.userProfile.firstName;
  }

  get tasks(): CompanyTask[] {
    if (!this.activeCompanyView) {
      return this.companies
        .reduce((acc, company) => {
          acc.push(...company.tasks);
          return acc;
        }, [] as CompanyTask[])
        .sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());
    }

    return this.activeCompanyView.tasks.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());
  }

  ngOnInit(): void {
    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.companies = data.connections;
    });

    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((params) => {
      const companyId = params.id ? Number(params.id) : undefined;

      if (!companyId) {
        this.activeCompanyView = undefined;
        return;
      }

      this.activeCompanyView = this.companies.find((company) => company.id === companyId);
      this.store.dispatch(new LoadDocuments(String(companyId)));
    });
  }

  onScroll(event: Event): void {
    this.hideHeaderBorder = (event.target as HTMLElement).scrollTop < 12;
  }

  navigateToOrder(order: BPIOrder): void {
    this.router.navigate(['/bpi', 'order', order.id, 'details']);
  }

  navigateToInvoice(invoice: BPIInvoice): void {
    this.router.navigate(['/bpi', 'invoice', invoice.id, 'details']);
  }
}
