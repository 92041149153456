import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageWithSubnavComponent } from '@design/layout/page-with-subnav/page-with-subnav.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'cc-tasks-v2',
  standalone: true,
  templateUrl: './tasks-page.component.html',
  styleUrl: './tasks-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PageWithSubnavComponent, RouterOutlet],
})
export class TasksPageComponent {}
