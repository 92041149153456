import { ChangeDetectionStrategy, Component, type OnInit, ViewEncapsulation, inject, output } from '@angular/core';
import { ImpersonateModalComponent } from '../../modals/impersonate-modal/impersonate-modal.component';
import { type ConnectedUser, type User } from '../../models/user';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { takeWhile } from 'rxjs/operators';
import { type Company } from '../../models/company';
import { UserLogoComponent } from '@core/components/user-logo/user-logo.component';

import { CompanyLogoComponent } from '@core/components/company-logo/company-logo.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'app-profile-dropdown-menu',
  templateUrl: './profile-dropdown-menu.component.html',
  styleUrls: ['./profile-dropdown-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UserLogoComponent, CompanyLogoComponent, TranslateModule, RouterLink, FocusableDirective],
})
export class ProfileDropdownMenuComponent implements OnInit {
  private readonly modalService = inject(ModalService);
  private readonly userService = inject(UserService);
  public currentUser: User;
  public userCompany: Company;
  public isImpersonationAllowed = false;
  public selectedUserId: number;
  public availableUsers: ConnectedUser[] = [];
  currentConnectedUserChange = output<ConnectedUser | undefined>();

  private isAlive = true;

  public get mainUserData(): { connectedUsers: ConnectedUser[]; company: Company; userProfile: User } {
    return this.userService.mainUserData;
  }

  public get isSwitchedProfile(): boolean {
    return this.userService.isSwitchedProfile;
  }

  public get isImpersonating(): boolean {
    return this.userService.isImpersonating;
  }

  ngOnInit(): void {
    this.userService.profile$.pipe(takeWhile((_) => this.isAlive)).subscribe((user) => {
      if (!user) {
        return;
      }
      this.currentUser = this.isSwitchedProfile ? this.mainUserData?.userProfile : user;
      this.userCompany = this.isSwitchedProfile ? this.mainUserData?.company : this.userService.userCompany;
      this.availableUsers = this.isSwitchedProfile
        ? [...this.mainUserData?.connectedUsers]
        : [...this.userService.connectedUsers];
      this.isImpersonationAllowed = this.userService.permissions
        ? this.userService.permissions.User_Impersonate
        : false;

      if (this.availableUsers?.length && this.userCompany && this.currentUser) {
        this.availableUsers.unshift({
          companyId: this.userCompany.id,
          companyLogoUrl: this.userCompany.logoUrl,
          companyName: this.userCompany.name,
          userId: this.currentUser.id,
        });

        this.selectedUserId = this.userService.userProfile.id;
      }

      const currentConnectedUser = this.availableUsers.find((u) => u.userId === this.selectedUserId);
      this.currentConnectedUserChange.emit(currentConnectedUser);
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  public onImpersonateClick(): void {
    this.modalService.open({
      content: ImpersonateModalComponent,
      options: {
        backdrop: 'static',
      },
    });
  }

  public signOut(): void {
    this.userService.logout();
  }

  public selectUser(user: ConnectedUser): void {
    if (this.isImpersonating || this.selectedUserId === user.userId) {
      return;
    }

    if (user.userId === this.currentUser.id) {
      this.userService.exitImpersonation();
      return;
    }

    this.selectedUserId = user.userId;
    this.userService.switchProfile(user);
  }
}
