import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { ButtonComponent } from '../../../../stories/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'cc-campaigns-header',
  standalone: true,
  templateUrl: './campaigns-header.component.html',
  styleUrl: './campaigns-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, RouterLink],
})
export class CampaignsHeaderComponent {
  export = output<void>();

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
}
