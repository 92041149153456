import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from '@core/services/toastr.service';
import { AuthService } from '../../auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FormsModule } from '@angular/forms';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@UntilDestroy()
@Component({
  selector: 'app-decline-invitation-modal',
  templateUrl: './decline-invitation-modal.component.html',
  styleUrls: ['./decline-invitation-modal.component.scss'],
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective, FormsModule, ButtonLoadingDirective, TranslateModule],
})
export class DeclineInvitationModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  public readonly authService = inject(AuthService);
  private readonly toastr = inject(ToastrService);
  @Input() companyName: string;
  @Input() token: string;

  public isLoading = false;
  public reasonText = '';

  public declineInvitation(): void {
    this.isLoading = true;

    this.authService
      .declineInvitation({
        token: this.token,
        reason: this.reasonText,
      })
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(() => {
        this.activeModal.close();
      });
  }
}
