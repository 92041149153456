import { type Routes } from '@angular/router';
import { provideStates } from '@ngxs/store';
import { PermissionsGuard } from './../core/guards/permissions.guard';
import { campaignsListGuard } from './campaigns-list.guard';
import { campaignsListResolver } from './campaigns-list.resolver';
import { CampaignsListComponent } from './campaigns-list/campaigns-list.component';
import { CampaignsPageComponent } from './campaigns-page.component';
import { CampaignsState } from './state/campaigns.state';

export const CAMPAIGNS_BASE_URL = 'campaigns';

export const campaignsPageRoutes: Routes = [
  {
    path: '',
    providers: [provideStates([CampaignsState])],
    component: CampaignsPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        resolve: {
          campaignsLoaded: campaignsListResolver,
        },
        data: {
          permissions: ['Campaign_View'],
        },
        canActivate: [PermissionsGuard, campaignsListGuard],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        component: CampaignsListComponent,
      },
    ],
  },
];
