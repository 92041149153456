import { DialogModule } from '@angular/cdk/dialog';
import { OverlayModule, type ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  inject,
  Injector,
  model,
  type OnInit,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { CampaignStatus, type Campaign, type CampaignPreview } from '@clover/campaigns-v2/state/campaigns-state.model';
import { CampaignsService } from '@clover/campaigns-v2/state/campaigns.service';
import { getOverlayVisibilityAfterOutsideClick } from '@clover/core/helpers/get-overlay-visibility-after-outside-click';
import { colorFormatter, percentageFormatter } from '@clover/core/helpers/nz-progress-formatters';
import { DateFormatDistancePipe } from '@clover/core/pipes/date-format.pipe';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TdComponent } from '@design/table/td/td.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzProgressComponent } from 'ng-zorro-antd/progress';
import { ButtonComponent } from '../../../../../stories/buttons/button/button.component';
import { DropdownActionComponent } from '../../../../../stories/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '../../../../../stories/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownComponent } from '../../../../../stories/overlays/dropdown/dropdown.component';
import { CampaignStatusChipComponent } from './campaign-status-chip/campaign-status-chip.component';

@UntilDestroy()
@Component({
  selector: 'cc-campaign-table-row',
  standalone: true,
  imports: [
    TdComponent,
    DateFormatDistancePipe,
    CampaignStatusChipComponent,
    ButtonComponent,
    DropdownComponent,
    TooltipDirective,
    DropdownActionComponent,
    DropdownDividerComponent,
    OverlayModule,
    DialogModule,
    RouterLink,
    NzProgressComponent,
  ],
  templateUrl: './campaign-table-row.component.html',
  styleUrl: './campaign-table-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignTableRowComponent implements OnInit {
  // Model is used here instead of input, to allow changing the value within the component
  // InputSignals are read-only, ModelSignals are not
  campaign = model<CampaignPreview>();

  completionColor = computed(() => colorFormatter(this.campaign().completion));

  protected dropdownVisible = false;
  protected readonly dropdownPositionStrategy: ConnectedPosition[] = [
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetY: 4 },
  ];

  protected readonly CampaignStatus = CampaignStatus;
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly campaignsService = inject(CampaignsService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly injector = inject(Injector);

  protected readonly percentageFormatter = percentageFormatter;

  ngOnInit(): void {
    this.campaignsService
      .subscribeToCampaignUpdates(this.campaign().id)
      .pipe(untilDestroyed(this))
      .subscribe((campaign: Campaign): void => {
        this.campaign.set(campaign as CampaignPreview);
        this.cdr.detectChanges();
      });
  }

  sendReminders(): void {
    this.campaignsService.sendReminder(this.campaign().id);
  }

  async closeCampaign(): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: 'Are you sure you want to close this campaign?',
        description:
          'The selected campaign will be closed and any task assignments that are not already started will be removed from their assigned users.',
        confirmActionText: 'Close campaign',
        cancelActionText: 'Cancel',
        destructive: true,
        style: 'default',
      })) === 'confirm';

    if (confirmed) this.campaignsService.closeCampaign(this.campaign().id);
  }

  async publishDraftCampaign(): Promise<void> {
    const companiesCount = this.campaign().companiesCount;

    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: 'Are you sure you want to publish this campaign?',
        description: `The selected campaign will be published to ${companiesCount} ${companiesCount === 1 ? 'company' : 'companies'}.`,
        confirmActionText: 'Publish campaign',
        cancelActionText: 'Cancel',
        destructive: false,
        style: 'default',
      })) === 'confirm';

    if (confirmed) this.campaignsService.publishDraftCampaign(this.campaign().id);
  }

  async deleteDraftCampaign(): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: 'Are you sure you want to delete this campaign?',
        description:
          'You are about the delete the selected campaign. This cannot be undone. Are you sure you want to delete it?',
        confirmActionText: 'Delete campaign',
        cancelActionText: 'Cancel',
        destructive: true,
        style: 'default',
      })) === 'confirm';

    if (confirmed) this.campaignsService.deleteDraftCampaign(this.campaign().id);
  }
}
