import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthSigninComponent } from './signin/signin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TfaModalComponent } from './modals/tfa-modal/tfa-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthSignupComponent } from './signup/signup.component';
import { AuthSocialSignupComponent } from './social-signup/social-signup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './auth.service';
import { TermsModalComponent } from './modals/terms-modal/terms-modal.component';
import { EmailSentComponent } from './email-sent/email-sent.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { CompanySelectComponent } from './signup-steps/company-select/company-select.component';
import { ProfileSetupComponent } from './signup-steps/profile-setup/profile-setup.component';
import { EnableTfaModalComponent } from './modals/enable-tfa-modal/enable-tfa-modal.component';
import { NgxMaskDirective } from 'ngx-mask';
import { ConnectionInvitationPageComponent } from './connection-invitation-page/connection-invitation-page.component';
import { InvitationRequiredPageComponent } from './error-pages/invitation-required-page/invitation-required-page.component';
import { EmailNotAuthorizedPageComponent } from './error-pages/email-not-authorized-page/email-not-authorized-page.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordResetSentComponent } from './password-reset-sent/password-reset-sent.component';
import { PassTokenExpiredComponent } from './error-pages/pass-token-expired/pass-token-expired.component';
import { EmailConfirmTokenExpiredComponent } from './error-pages/email-confirm-token-expired/email-confirm-token-expired.component';
import { InvitationExpiredComponent } from './error-pages/invitation-expired/invitation-expired.component';
import { InvitationInactivePageComponent } from './error-pages/invitation-inactive-page/invitation-inactive-page.component';
import { CompanyListsModule } from '../company-lists/company-lists.module';
import { MaintenancePageComponent } from './error-pages/maintenance-page/maintenance-page.component';
import { InvitationAlreadyAcceptedPageComponent } from './error-pages/invitation-already-accepted-page/invitation-already-accepted-page.component';
import { InterviewComponent } from './signup-steps/interview/interview.component';
import { AuthStepIndicatorComponent } from './auth-step-indicator/auth-step-indicator.component';
import { DeclineInvitationModalComponent } from './connection-invitation-page/modals/decline-invitation-modal.component';
import { InvitationSignupComponent } from './signup-steps/invitation-signup/invitation-signup.component';
import { CompanyTypeSelectComponent } from './signup-steps/company-type-select/company-type-select.component';
import { CompanyAccessRequestEmailConfirmation } from './company-access-request-email-confirmation/company-access-request-email-confirmation.component';
import { CompanyAccessRequestsModule } from '../company-access-requests/company-access-requests.module';
import { NotFoundPageComponent } from './error-pages/not-found-page/not-found-page.component';

import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { CompanyInfoComponent } from '@core/components/company-info/company-info.component';
import { LoaderComponent } from '@core/components/loader/loader.component';
import { CompanyLogoComponent } from '@core/components/company-logo/company-logo.component';
import { RichTextEditorComponent } from '@core/components/rich-text-editor/rich-text-editor.component';
import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { AddressInputComponent } from '@core/components/address-input/address-input.component';
import { AddressSelectionComponent } from '@core/components/address-selection/address-selection.component';
import { SelectComponent } from '@core/components/select/select.component';
import { PasswordInputComponent } from '@core/components/password-input/password-input.component';
import { ImageUploaderComponent } from '@core/components/image-uploader/image-uploader.component';
import { TooltipComponent } from '@core/components/tooltip/tooltip.component';
import { CommunicationRolesComponent } from '@core/components/communication-roles/communication-roles.component';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CompanyListsModule,
    NgbModule,
    CompanyAccessRequestsModule,
    TranslateModule.forChild(),
    FormErrorMessagesComponent,
    NgxMaskDirective,
    AssetSrcDirective,
    ButtonLoadingDirective,
    CompanyInfoComponent,
    LoaderComponent,
    CompanyLogoComponent,
    RichTextEditorComponent,
    ScrollableAreaComponent,
    FocusableDirective,
    AddressInputComponent,
    AddressSelectionComponent,
    SelectComponent,
    PasswordInputComponent,
    ImageUploaderComponent,
    TooltipComponent,
    CommunicationRolesComponent,
    AuthSigninComponent,
    AuthSignupComponent,
    AuthSocialSignupComponent,
    TfaModalComponent,
    TermsModalComponent,
    EmailSentComponent,
    EmailConfirmationComponent,
    CompanySelectComponent,
    CompanyTypeSelectComponent,
    ProfileSetupComponent,
    EnableTfaModalComponent,
    ConnectionInvitationPageComponent,
    InvitationRequiredPageComponent,
    EmailNotAuthorizedPageComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PasswordResetSentComponent,
    InvitationRequiredPageComponent,
    PassTokenExpiredComponent,
    EmailConfirmTokenExpiredComponent,
    InvitationExpiredComponent,
    InvitationInactivePageComponent,
    MaintenancePageComponent,
    InvitationAlreadyAcceptedPageComponent,
    InterviewComponent,
    AuthStepIndicatorComponent,
    DeclineInvitationModalComponent,
    InvitationSignupComponent,
    CompanyAccessRequestEmailConfirmation,
    NotFoundPageComponent,
  ],
  providers: [AuthService],
})
export class AuthModule {}
