import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'app-password-reset-sent',
  templateUrl: './password-reset-sent.component.html',
  styleUrls: ['./password-reset-sent.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class PasswordResetSentComponent {}
