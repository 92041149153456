import { Selector } from '@ngxs/store';
import type { WorkflowPreview, WorkflowsStateModel, FilterableWorkflowStatus } from './workflows-state.model';
import type { SortingOptions } from '@clover/core/helpers/paging';
import type { WorkflowsSortingProperty } from './workflows.service';
import { WorkflowsState } from './workflows.state';

export class WorkflowsSelectors {
  @Selector([WorkflowsState])
  static workflows(state: WorkflowsStateModel): WorkflowPreview[] {
    return state.workflows?.data || [];
  }

  @Selector([WorkflowsState])
  static workflowsCount(state: WorkflowsStateModel): number {
    return state.workflows?.total || 0;
  }

  @Selector([WorkflowsState])
  static workflowsLoadingStatus(state: WorkflowsStateModel): 'void' | 'loading' | 'loaded' | 'error' | 'loading-next' {
    return state.loadingStatus;
  }

  @Selector([WorkflowsState])
  static workflowsQuery(state: WorkflowsStateModel): string {
    return state.query;
  }

  @Selector([WorkflowsState])
  static workflowsStatus(state: WorkflowsStateModel): FilterableWorkflowStatus {
    return state.status;
  }

  @Selector([WorkflowsState])
  static workflowsSortingOptions(state: WorkflowsStateModel): SortingOptions<WorkflowsSortingProperty> {
    return state.sorting;
  }
}
