<cc-table
  [ccEmptyRowTemplate]="emptyRowTemplate"
  [ccSearchPlaceholder]="'Search for a company...'"
  ccPadding="small"
>
  <ng-container slot="cc-table-title"> Open Invitations</ng-container>

  <ng-container slot="cc-table">
    <thead>
      <tr>
        <th
          ccTh
          ccWidth="100%"
        >
          Company
        </th>
        <th ccTh></th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let invitation of invitations">
        <tr>
          <td>
            <div class="company-column">
              <img
                [alt]="invitation.companyName"
                [ngSrc]="invitation.companyLogo || 'assets/svg/common/new-company-placeholder.svg'"
                class="company-column__logo"
                height="88"
                width="88"
              />

              <div class="company-column__details">
                <div class="company-column__details-name">
                  {{ invitation.companyName }}
                </div>

                <div class="company-column__details-timestamp">
                  Invited {{ invitation.date | ccDateFormatDistance: { strict: true, addSuffix: false } }} ago
                </div>
              </div>
            </div>
          </td>

          <td>
            <div class="buttons-column">
              <app-button
                (click)="declineInvitation(invitation)"
                [size]="ButtonSize.Small"
                [type]="ButtonType.GhostText"
                ccTooltip="Decline"
              >
                <i
                  class="icon-close"
                  slot="ghost-icon"
                ></i>
              </app-button>

              <app-button
                (click)="acceptInvitation(invitation)"
                [size]="ButtonSize.Small"
                [type]="ButtonType.Primary"
              >
                Accept
              </app-button>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </ng-container>
</cc-table>

<ng-template #emptyRowTemplate> There are no open invitations</ng-template>
