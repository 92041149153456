<cc-table
  [ccEmptyRowTemplate]="emptyRowTemplate"
  [ccSearchPlaceholder]="'Search for a task...'"
  ccPadding="medium"
>
  <ng-container slot="cc-table-title"> Tasks</ng-container>

  <ng-container slot="cc-table">
    <thead>
      <tr>
        <th ccTh>Task</th>

        <th
          ccTh
          ccWidth="120px"
        >
          <div style="display: flex; gap: 4px; align-items: center">
            SLA

            <i
              class="icon-info-fill"
              style="font-size: 16px; display: flex; color: #8d969f; cursor: pointer"
            ></i>
          </div>
        </th>

        <th
          ccHorizontalAlignment="center"
          ccTh
          ccWidth="96px"
        >
          Assigner
        </th>

        <th
          ccHorizontalAlignment="center"
          ccTh
          ccWidth="96px"
        >
          Assignee
        </th>

        <th
          ccTh
          ccWidth="48px"
        ></th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let task of tasks">
        <tr ccTr>
          <td>
            <div class="task-column">
              <div class="task-column__details">
                <div class="task-column__details-title">{{ task.title }}</div>

                <cc-task-chip [status]="task.status"></cc-task-chip>
              </div>

              <div class="task-column__metadata">
                <div class="task-column__metadata-id">#{{ task.id }}</div>

                <div class="task-column__metadata-divider"></div>

                <div class="task-column__metadata-timestamp">
                  Assigned {{ task.assignedAt | ccDateFormatDistance: { strict: true } }} ago
                </div>
              </div>

              <div class="task-column__description">
                {{ task.description }}
              </div>
            </div>
          </td>

          <td
            ccTd
            ccVerticalAlignment="start"
          >
            <div
              [class.sla-column--overdue]="getSlaOverdue(task)"
              class="sla-column"
            >
              {{ task.dueDate | ccDateFormatDistance: { strict: true, locale: getSlaLocale() } }}
              {{ getSlaOverdue(task) ? 'overdue' : 'left' }}
            </div>
          </td>

          <td
            ccTd
            ccVerticalAlignment="start"
          >
            <div class="performer-column">
              <img
                [alt]="task.assignee.name"
                [ccTooltip]="task.assignee.name"
                [src]="task.assignee.avatar"
              />
            </div>
          </td>

          <td
            ccTd
            ccVerticalAlignment="start"
          >
            <div class="performer-column">
              <img
                [alt]="task.assigner.name"
                [ccTooltip]="task.assigner.name"
                [src]="task.assigner.avatar"
              />
            </div>
          </td>

          <td
            ccTd
            ccVerticalAlignment="start"
          >
            <app-button
              [size]="ButtonSize.Small"
              [type]="ButtonType.Ghost"
              ccTooltip="More actions"
            >
              <i
                class="icon-more"
                slot="ghost-icon"
              ></i>
            </app-button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </ng-container>
</cc-table>

<ng-template #emptyRowTemplate> There are no tasks to display.</ng-template>
