import { ResolveFn } from '@angular/router';
import { LoadWorkflows } from './state/workflows.actions';
import { workflowStatusRoutesMap } from './workflows-list.guard';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, map, of } from 'rxjs';

export const workflowsListResolver: ResolveFn<boolean> = (route) => {
  const { query = '', status: statusRoute } = route.queryParams;

  const status = workflowStatusRoutesMap.get(statusRoute);

  const store = inject(Store);
  return store.dispatch(new LoadWorkflows({ query, status })).pipe(
    map(() => true),
    catchError(() => of(false)),
  );
};
