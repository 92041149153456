<cc-page-with-subnav id="dashboard-v2">
  <ng-container slot="content">
    <ng-scrollbar visibility="hover">
      <div
        (scroll)="onScroll($event)"
        cdkScrollable
        scrollViewport
      >
        <div class="dashboard">
          <div
            [class.dashboard__header--no-border]="hideHeaderBorder"
            class="dashboard__header"
          >
            <h1 class="dashboard__header-greeting">{{ greeting }}, {{ userName }}!</h1>

            <div class="dashboard__header-view-select">
              <div class="dashboard__header-view-select-label">Viewing:</div>

              <div
                #picker
                (click)="dropdownOpen = !dropdownOpen"
                (keyup.enter)="dropdownOpen = !dropdownOpen"
                class="dashboard__header-view-select-picker"
                role="button"
                tabindex="0"
              >
                <ng-container *ngIf="activeCompanyView">
                  <img
                    [src]="activeCompanyView.logo || 'assets/svg/common/new-company-placeholder.svg'"
                    alt="Company logo"
                    class="dashboard__header-view-select-picker-icon"
                    height="48"
                    width="48"
                  />
                </ng-container>

                <span class="dashboard__header-view-select-picker-title">
                  {{ activeCompanyView ? activeCompanyView.name : 'All connections' }}
                </span>

                <i
                  [class.icon-collapse]="dropdownOpen"
                  [class.icon-expand]="!dropdownOpen"
                ></i>
              </div>
            </div>
          </div>

          <ng-container *ngIf="!activeCompanyView">
            <div class="dashboard_invitations">
              <cc-invitations></cc-invitations>
            </div>
          </ng-container>

          <ng-container *ngIf="activeCompanyView && activeCompanyView.quickLinks.length">
            <div class="dashboard_company-quick-links">
              <cc-company-quick-links [quickLinks]="activeCompanyView.quickLinks"></cc-company-quick-links>
            </div>
          </ng-container>

          <ng-container *ngIf="activeCompanyView">
            <cc-company-wiki class="dashboard_company-wiki"></cc-company-wiki>
          </ng-container>

          <ng-container *ngIf="activeCompanyView && activeCompanyView.statistics.length">
            <div class="dashboard_company-stats">
              <cc-company-stats [stats]="activeCompanyView.statistics"></cc-company-stats>
            </div>
          </ng-container>

          <div class="dashboard_company-tasks">
            <cc-company-tasks [tasks]="tasks"></cc-company-tasks>
          </div>

          <ng-container *ngIf="activeCompanyView">
            <div class="dashboard_column-layout">
              <cc-company-orders
                (orderClick)="navigateToOrder($event)"
                [clickable]="true"
                [orders]="activeCompanyView.orders"
                class="dashboard_column-layout-item"
              />

              <cc-company-invoices
                (invoiceClick)="navigateToInvoice($event)"
                [clickable]="true"
                [invoices]="activeCompanyView.invoices"
                class="dashboard_column-layout-item"
              />

              <cc-company-payments
                [payments]="activeCompanyView.payments"
                class="dashboard_column-layout-item"
              />
            </div>
          </ng-container>
        </div>
      </div>
    </ng-scrollbar>
  </ng-container>
</cc-page-with-subnav>

<ng-template
  (detach)="dropdownOpen = false"
  (overlayOutsideClick)="dropdownOpen = getOverlayVisibilityAfterOutsideClick($event, picker)"
  [cdkConnectedOverlayOpen]="dropdownOpen"
  [cdkConnectedOverlayOrigin]="picker"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown
    width="260px"
    maxHeight="300px"
  >
    @for (company of companies; track company.id) {
      <cc-dropdown-action
        #companyActive="routerLinkActive"
        routerLinkActive
        (click)="dropdownOpen = false"
        [routerLink]="['/', 'dashboard-v2', company.id]"
        [selectable]="true"
        [routerLinkActiveOptions]="{ exact: true }"
        [selected]="companyActive.isActive"
      >
        <ng-container slot="title">
          <div class="company-dropdown-item">
            <img
              [ngSrc]="company.logo || 'assets/svg/common/new-company-placeholder.svg'"
              alt="Company logo"
              class="company-dropdown-item__logo"
              height="48"
              width="48"
            />

            <div class="company-dropdown-item__name">
              {{ company.name }}
            </div>
          </div>
        </ng-container>
      </cc-dropdown-action>
    }

    <cc-dropdown-divider />

    <cc-dropdown-action
      #allConnectionActive="routerLinkActive"
      (click)="dropdownOpen = false"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="['/', 'dashboard-v2']"
      [selectable]="true"
      [selected]="allConnectionActive.isActive"
      routerLinkActive
    >
      <ng-container slot="title">All connections</ng-container>
    </cc-dropdown-action>
  </cc-dropdown>
</ng-template>
