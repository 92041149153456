import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { CompanyService } from '@clover/network-v2/company/state/company.service';
import {
  MOCK_BPI_INVOICES,
  MOCK_BPI_ORDERS,
  MOCK_BPI_PAYMENTS,
} from '@design/overlays/business-object-modal/business-object';
import { map, type Observable } from 'rxjs';
import {
  DEFAULT_QUICK_LINKS,
  DEFAULT_STATISTICS,
  DEFAULT_TASKS,
  RETAIL_QUICK_LINKS,
  RETAIL_STATISTICS,
  RETAIL_TASKS,
  type Company,
} from './mocks';

export const dashboardConnectionsResolver: ResolveFn<Company[]> = (): Observable<Company[]> => {
  const companyService = inject(CompanyService);

  return companyService.getMyCompanyConnections(1000).pipe(
    map((companiesResponse) => {
      const { data: companiesData = [] } = companiesResponse;

      return companiesData.map((company) => {
        const retailerCompanyStates = ['FL'];
        const isRetailerCompany = company?.address?.state && retailerCompanyStates.includes(company.address.state);

        return {
          id: company.id,
          name: company.name,
          logo: company.logoUrl,
          quickLinks: isRetailerCompany ? RETAIL_QUICK_LINKS : DEFAULT_QUICK_LINKS,
          statistics: isRetailerCompany ? RETAIL_STATISTICS : DEFAULT_STATISTICS,
          tasks: isRetailerCompany ? RETAIL_TASKS : DEFAULT_TASKS,
          orders: MOCK_BPI_ORDERS,
          invoices: MOCK_BPI_INVOICES,
          payments: MOCK_BPI_PAYMENTS,
        };
      });
    }),
  );
};
