import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanyStatistic } from '@clover/dashboard-v2/mocks';

@Component({
  selector: 'cc-company-stats',
  standalone: true,
  imports: [NgForOf],
  templateUrl: './company-stats.component.html',
  styleUrls: ['./company-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyStatsComponent {
  @Input()
  stats: CompanyStatistic[] = [];
}
