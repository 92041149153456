import { CurrencyPipe, NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskStatusChipComponent } from '@clover/conversations-v4/tasks/task-status-chip/task-status-chip.component';
import { CoreModule } from '@core/core.module';
import { ButtonComponent } from '@design/buttons/button/button.component';
import type { BPIOrder } from '@design/overlays/business-object-modal/business-object';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TableComponent } from '@design/table/table.component';
import { TdComponent } from '@design/table/td/td.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

@Component({
  selector: 'cc-company-orders',
  standalone: true,
  imports: [
    ButtonComponent,
    CoreModule,
    TableComponent,
    TaskStatusChipComponent,
    TdComponent,
    ThComponent,
    TooltipDirective,
    TrComponent,
    NgForOf,
    CurrencyPipe,
  ],
  templateUrl: './company-orders.component.html',
  styleUrls: ['./company-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyOrdersComponent {
  @Input()
  orders: BPIOrder[] = [];

  @Input()
  clickable = false;

  @Output()
  orderClick = new EventEmitter<BPIOrder>();

  getStatusText(order: BPIOrder): string {
    switch (order.status) {
      case 'partially-invoiced':
        return 'Partially Invoiced';
      default:
        return 'Unknown';
    }
  }

  getStatusFgColor(order: BPIOrder): string {
    switch (order.status) {
      case 'partially-invoiced':
        return '#725616';
      default:
        return '#000000';
    }
  }

  getStatusBgColor(order: BPIOrder): string {
    switch (order.status) {
      case 'partially-invoiced':
        return '#F9E8C0';
      default:
        return '#FFFFFF';
    }
  }
}
