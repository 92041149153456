import { Action, State, type StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { catchError, map, of, tap, type Observable } from 'rxjs';
import * as R from 'ramda';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { defaultCampaignsState, type CampaignsStateModel } from './campaigns-state.model';
import { CampaignsService } from './campaigns.service';
import { LoadCampaigns, LoadNextCampaigns, PatchCampaign, RemoveCampaign } from './campaigns.actions';
import { CdkPortalService } from '@clover/core/services/cdk-portal.service';

@State<CampaignsStateModel>({
  name: 'campaigns',
  defaults: defaultCampaignsState,
})
@Injectable()
export class CampaignsState {
  private readonly campaignsService = inject(CampaignsService);
  private readonly portalService = inject(CdkPortalService);

  @Action(LoadCampaigns, { cancelUncompleted: true })
  loadCampaigns(ctx: StateContext<CampaignsStateModel>, { payload }: LoadCampaigns): Observable<void> {
    const state = ctx.getState();
    const query = R.isNil(payload.query) ? state.query : payload.query;
    const status = R.isNil(payload.status) ? state.status : payload.status;
    const sorting = payload.sortingOptions || state.sorting;

    ctx.patchState({ loadingStatus: 'loading' });

    return this.campaignsService
      .searchCampaigns(query, status, {
        offset: 0,
        orderBy: sorting.orderBy,
        order: sorting.order,
        limit: 50,
      })
      .pipe(
        tap((campaigns) => {
          ctx.patchState({ campaigns, query, status, sorting, loadingStatus: 'loaded' });
        }),
        catchError(() => {
          ctx.patchState({ loadingStatus: 'error' });
          return of(undefined);
        }),
        map(() => undefined),
      );
  }

  @Action(LoadNextCampaigns, { cancelUncompleted: true })
  loadNextCampaigns(ctx: StateContext<CampaignsStateModel>): Observable<void> {
    const state = ctx.getState();
    const offset = state.campaigns.count;
    const query = state.query;
    const status = state.status;
    const sortingOptions = state.sorting;

    ctx.patchState({ loadingStatus: 'loading-next' });

    return this.campaignsService
      .searchCampaigns(query, status, {
        offset,
        orderBy: sortingOptions.orderBy,
        order: sortingOptions.order,
        limit: 50,
      })
      .pipe(
        tap((nextCampaigns) => {
          ctx.setState(
            patch<CampaignsStateModel>({
              campaigns: patch({
                data: append(nextCampaigns.data),
                count: state.campaigns.count + nextCampaigns.count,
                paging: nextCampaigns.paging,
              }),
              loadingStatus: 'loaded',
            }),
          );
        }),
        catchError(() => {
          ctx.patchState({ loadingStatus: 'error' });
          return of(undefined);
        }),
        map(() => undefined),
      );
  }

  @Action(PatchCampaign)
  patchCampaign(ctx: StateContext<CampaignsStateModel>, { campaignId, patch: patchedValue }: PatchCampaign): void {
    ctx.setState(
      patch<CampaignsStateModel>({
        campaigns: patch({
          data: updateItem((campaign) => campaign.id === campaignId, patchedValue),
        }),
      }),
    );
  }

  @Action(RemoveCampaign)
  removeCampaign(ctx: StateContext<CampaignsStateModel>, { campaignId }: RemoveCampaign): void {
    ctx.setState(
      patch<CampaignsStateModel>({
        campaigns: patch({
          data: removeItem((campaign) => campaign.id === campaignId),
          count: (count) => count - 1,
          total: (total) => total - 1,
        }),
      }),
    );
  }
}
