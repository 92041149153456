import { type Routes } from '@angular/router';
import { provideStates } from '@ngxs/store';
import { PermissionsGuard } from './../core/guards/permissions.guard';
import { productsListGuard } from './products-list.guard';
import { productsListResolver } from './products-list.resolver';
import { ProductsListComponent } from './products-list/products-list.component';
import { ProductsPageComponent } from './products-page.component';
import { ProductsState } from './state/products.state';

export const PRODUCTS_BASE_URL = 'products-v2';

export const productsPageRoutes: Routes = [
  {
    path: '',
    providers: [provideStates([ProductsState])],
    component: ProductsPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        resolve: {
          productsLoaded: productsListResolver,
        },
        data: {
          permissions: ['Product_View'],
        },
        canActivate: [PermissionsGuard, productsListGuard],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        component: ProductsListComponent,
      },
    ],
  },
];
