import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit, inject } from '@angular/core';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, type Params } from '@angular/router';
import { workflowStatusRoutesMap, workflowStatusToRouteParam } from '@clover/workflows-v2/workflows-list.guard';
import { FilterableWorkflowStatus } from '@clover/workflows-v2/state/workflows-state.model';
import { LoadWorkflows } from '@clover/workflows-v2/state/workflows.actions';
import { WorkflowsSelectors } from '@clover/workflows-v2/state/workflows.selectors';
import type { WorkflowsSortingProperty } from '@clover/workflows-v2/state/workflows.service';
import { WorkflowStatus } from '@clover/workflows/models/workflow';
import { sortDirectionToPagingOrder } from '@clover/core/helpers/paging';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import type { SelectItem } from '@design/forms/select/select-item/select-item';
import { SelectItemComponent } from '@design/forms/select/select-item/select-item.component';
import { SelectComponent } from '@design/forms/select/select.component';
import type { SortDirection } from '@design/table/table';
import { TableComponent } from '@design/table/table.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { type Observable, map, debounceTime, distinctUntilKeyChanged } from 'rxjs';
import { WorkflowTableRowComponent } from './workflow-table-row/workflow-table-row.component';

const statusSelectItems: SelectItem<FilterableWorkflowStatus>[] = [
  {
    id: 'all',
    title: 'All workflows',
    payload: FilterableWorkflowStatus.All,
  },
  {
    id: 'published',
    title: 'Published',
    payload: FilterableWorkflowStatus.Published,
  },
  {
    id: 'draft',
    title: 'Draft',
    payload: FilterableWorkflowStatus.Draft,
  },
];

@UntilDestroy()
@Component({
  selector: 'cc-workflows-table',
  standalone: true,
  imports: [
    TableComponent,
    AsyncPipe,
    TrComponent,
    ThComponent,
    SelectComponent,
    SelectItemComponent,
    ReactiveFormsModule,
    WorkflowTableRowComponent,
  ],
  templateUrl: './workflows-table.component.html',
  styleUrl: './workflows-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowsTableComponent implements OnInit {
  workflows$ = inject(Store).select(WorkflowsSelectors.workflows);
  workflowsCount$ = inject(Store).select(WorkflowsSelectors.workflowsCount);
  loadingStatus$ = inject(Store).select(WorkflowsSelectors.workflowsLoadingStatus);
  sortingOptions$ = inject(Store).select(WorkflowsSelectors.workflowsSortingOptions);

  protected nameSortingOrder$: Observable<SortDirection>;
  protected typeSortingOrder$: Observable<SortDirection>;
  protected statusSortingOrder$: Observable<SortDirection>;

  protected queryFormControl = new FormControl<string>('');
  protected statusSelectFormControl = new FormControl<SelectItem<FilterableWorkflowStatus>>(undefined);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly WorkflowStatus = WorkflowStatus;
  protected readonly FilterableWorkflowStatus = FilterableWorkflowStatus;

  protected readonly statusSelectItems = statusSelectItems;

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  ngOnInit(): void {
    this.initQueryFilter();
    this.initStatusFilter();

    this.nameSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'name' ? sortingOptions.order : null)),
    );

    this.typeSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'type' ? sortingOptions.order : null)),
    );

    this.statusSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'status' ? sortingOptions.order : null)),
    );
  }

  changeSort(property: WorkflowsSortingProperty, direction: SortDirection): void {
    this.store.dispatch(
      new LoadWorkflows({
        sortingOptions: {
          order: sortDirectionToPagingOrder(direction),
          orderBy: property,
        },
      }),
    );
  }

  private initQueryFilter(): void {
    this.queryFormControl.setValue(this.activatedRoute.snapshot.queryParams['query'] || '', { emitEvent: false });

    this.queryFormControl.valueChanges.pipe(untilDestroyed(this), debounceTime(300)).subscribe((query) => {
      this.setRouteParams({ query: query || undefined });
    });
  }

  private initStatusFilter(): void {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this), distinctUntilKeyChanged('status'))
      .subscribe(({ status: statusParam }) => {
        const status = workflowStatusRoutesMap.get(statusParam);
        const selectItem = statusSelectItems.find((item) => item.payload === status);

        this.statusSelectFormControl.setValue(selectItem, { emitEvent: false });
      });

    this.statusSelectFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((selectItem) => {
      const status = selectItem.payload;
      const statusParam = workflowStatusToRouteParam(status);

      this.setRouteParams({ status: statusParam });
    });
  }

  private setRouteParams(params: Params): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
