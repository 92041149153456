@if (task$ | async; as task) {
  <cc-task-drawer
    (drawerClose)="close()"
    [task]="task"
  >
    @if (canOpenTaskWizard$ | async) {
      <app-button
        [routerLink]="['/', 'tasks', 'view', task.id]"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Primary"
      >
        {{ 'conversations-v4.tasks.openInTaskWizard' | translate }}
      </app-button>
    }
  </cc-task-drawer>
}
